import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type RoleCategoryCreateFormValues = {
    roleCategoryName: string
};

export const useRoleCategoryCreateForm = () => {
    const schema = roleCategoryCreateValidationSchema();

    return useForm<RoleCategoryCreateFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange"
    });
};

const roleCategoryCreateValidationSchema = () => {
    return yup
        .object({
            roleCategoryName: yup.string().required("Role category name is required."),
        })
        .required();
};