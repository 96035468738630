import { useNavigate } from "react-router-dom";

import FormContainer from "../components/FormContainer";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { capitalizeStrings } from "../utils/capitalizeString";
import { useRoleCategories } from "../api/roleCategories";
import { RoleCategoryCreateForm, RoleCategoryCreateFormValues } from "../components/RoleCategoryCreateForm";

const RoleCategoryCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createRoleCategory, isCreatingRoleCategory } = useRoleCategories();

    const onSubmit = async (values: RoleCategoryCreateFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createRoleCategory({
            ...values,
            roleCategoryName: capitalizeStrings(values.roleCategoryName)
        });

        if (response.success) {
            successToast("Category successfully created.");
            navigate(appPaths.roles.categories.index);
        }

        return response;
    };

    return (
        <FormContainer>
            <RoleCategoryCreateForm onSubmit={onSubmit} isSubmitting={isCreatingRoleCategory} />
        </FormContainer>
    );
};

export default RoleCategoryCreatePage;
