import { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { IconButton, TableCell } from "@mui/material";
import { Link } from "react-router-dom";

import { useTalents } from "../../api/talents";
import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import formatDateOnlyString from "../../utils/formatDateString";
import { useAppPaths } from "../../Routes";
import { useOrganizationConfig } from "../../api/organization";
import { TableHeaderConfig } from "../Table/Table";
import ProfileImageThumbnail from "../ProfileImageThumbnail";

export type TalentsTableProps = {
    searchTerm?: string,
    skillIds: string[] | undefined
    badgeIds: string[] | undefined
    isProfileCompleteFilter?: boolean
}

const TalentsTable = ({
    searchTerm,
    skillIds,
    badgeIds,
    isProfileCompleteFilter
}: TalentsTableProps) => {
    const talents = useTalents();
    const { gigDashboardDomain, organizationConfig, giggedClientTerminology, talentTerminologyPlural } = useOrganizationConfig();
    const setSearchTerm = talents.setSearchTerm;
    const appPaths = useAppPaths();

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    const handleOrderByChange = (property: string) => {
        const isAsc = talents.orderBy === property && talents.sortOrder === "asc";
        talents.setSortOrder(isAsc ? "desc" : "asc");
        talents.setOrderBy(property);
    };

    useEffect(() => {
        talents.setSkillIds(skillIds ?? []);
    }, [talents.setSkillIds, skillIds]);

    useEffect(() => {
        talents.setBadgeIds(badgeIds ?? []);
    }, [talents.setBadgeIds, badgeIds]);

    useEffect(() => {
        talents.setIsProfileCompleteFilter(isProfileCompleteFilter);
    }, [talents.setIsProfileCompleteFilter, isProfileCompleteFilter]);

    if (talents.isLoading) return (
        <Loader />
    );

    const headers: TableHeaderConfig[] = [
        {
            label: "",
            isSortable: false,
        },
        {
            label: "ID",
            isSortable: false,
        },
        {
            key: "FirstName",
            label: "First name",
            isSortable: true,
        },
        {
            key: "LastName",
            label: "Last name",
            isSortable: true,
        },
        {
            label: "Role",
            isSortable: false,
        },
        {
            label: giggedClientTerminology,
            isSortable: false,
        },
        {
            label: "Location",
            isSortable: false,
        },
        {
            key: "Email",
            label: "Email",
            isSortable: true,
        },
        {
            key: "ProfileHealthScore",
            label: "Profile health score",
            isSortable: true,
        },
        {
            key: "CreatedAt",
            label: "Date registered",
            isSortable: true,
        },
        {
            label: "View profile",
            isSortable: false,
        },
    ];

    if (organizationConfig?.isTalentAgencyEnabled || organizationConfig?.isBadgesEnabled) {
        headers.push(
            {
                label: "",
                isSortable: false
            }
        );
    }

    return (
        <TableCard>
            <Table
                headers={headers}
                data={talents.talents}
                rowHeight="73.5px"
                renderRow={talent => (
                    <>
                        <TableCell>
                            <ProfileImageThumbnail
                                name={talent.firstName}
                                userImageUrl={talent.profileImageThumbnailUrl}
                            />
                        </TableCell>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{talent.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.firstName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.lastName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.currentRoleTitle || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.currentGiggedClientName || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.location?.name|| "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{talent.email || "-"}</TableCell>
                        <TableCell>{talent.profileHealthScore}%</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(talent.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                            <a href={`https://${gigDashboardDomain}/find-${talentTerminologyPlural.toLowerCase()}/${talent.id}`} target="_blank">
                                <VisibilityIcon color="secondary" />
                            </a>
                        </TableCell>
                        {(organizationConfig?.isBadgesEnabled || organizationConfig?.isTalentAgencyEnabled) && (
                            <>
                                <TableCell sx={{ textAlign: "right" }}>
                                    <Link to={appPaths.talents.edit(talent.id)}>
                                        <IconButton aria-label="edit" >
                                            <ModeEditOutlineOutlinedIcon color="secondary" />
                                        </IconButton>
                                    </Link>
                                </TableCell>
                            </>
                        )}
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={talents.setPageIndex}
                setPageSize={talents.setPageSize}
                pageSize={talents.pageSize}
                pageIndex={talents.pageIndex}
                totalCount={talents.totalCount}
                orderBy={talents.orderBy}
                sortOrder={talents.sortOrder}
                isLoading={talents.isLoading}
            />
        </TableCard>
    );
};

export default TalentsTable;
