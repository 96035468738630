import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Box, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import ApiError from "../../api/common/apiError";
import ConfirmDialogBox from "../ConfirmDialogBox";
import FormTextInput from "../FormTextInput";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import FormInputLabel from "../FormInputLabel";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { SkillEditFormValues, useSkillEditForm } from "./SkillEditFormValues";
import { SkillDetailsDto } from "../../api/models/skills";
import { talentTooltips } from "../../tooltipsContent";
import { SkillCategoryInput } from "../SkillCategoriesSearchDropdown";
import { useOrganizationConfig } from "../../api/organization";

export type SkillEditFormProps = {
    onSubmit: (values: SkillEditFormValues) => Promise<GigApiFetcherResponse<void>>
    onDelete: () => Promise<GigApiFetcherResponse<void>>
    initialValues: SkillDetailsDto
    isDeleting: boolean
    isSubmitting: boolean
}

const SkillEditForm = ({
    onSubmit,
    onDelete,
    initialValues,
    isSubmitting,
    isDeleting
}: SkillEditFormProps) => {
    const appPaths = useAppPaths();
    const isLargeScreen = useIsLargeScreen();
    const { talentTerminology, talentTerminologyPlural, gigTerminologyPlural } = useOrganizationConfig();
    const navigate = useNavigate();
    const theme = useTheme();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const methods = useSkillEditForm({
        ...initialValues,
        skillName: initialValues.name
    });
    const { formState: { isDirty, errors } } = methods;

    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.skills.index);
        }
    });
    const [openDeleteDialog, deleteDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleDeleteSkill();
        }
    });

    useEffect(() => {
        methods.reset({
            ...initialValues,
            skillName: initialValues.name,
            skillCategoryId: initialValues.skillCategoryId
        });
    }, [methods.reset, initialValues]);

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.skills.index);
        }
    };

    const handleFormSubmit = async (values: SkillEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    const handleDeleteSkill = async () => {
        setSubmissionError(undefined);

        const response = await onDelete();

        if (!response.success) setSubmissionError(response.error);
        else navigate(appPaths.skills.index);
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title="Skill Edit"
                    >
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            },

                        }}>
                            <Box className="space-y-2">
                                <FormTextInput name="skillName" label="Skill name" disabled={isSubmitting} required />
                            </Box>
                            <Box className="space-y-2 flex flex-col">
                                <>
                                    <FormInputLabel tooltipText={talentTooltips.talentSkillCategory}>Skill category</FormInputLabel>
                                    <Controller
                                        name="skillCategoryId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <SkillCategoryInput
                                                tooltip={talentTooltips.talentSkillCategory}
                                                error={errors.skillCategoryId}
                                                onChange={onChange}
                                                value={value}
                                                isSubmitting={isSubmitting}
                                            />
                                        )}
                                    />
                                </>
                            </Box>
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "flex-end", marginTop: "2rem", gap: 2 } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "2rem" }
                        }>
                            <LoadingButton disabled={isDeleting || isSubmitting} startIcon={<DeleteForeverOutlined sx={isDeleting ? { opacity: "26%" } : { opacity: "100%" }} />} loading={isDeleting} color="secondary" onClick={openDeleteDialog}>Delete</LoadingButton>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                disabled={!isDirty}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Save changes
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            <ConfirmDialogBox
                {...deleteDialogProps}
                message={"Are you sure you want to delete this skill? This action cannot be undone."}
                warningMessage={
                    initialValues?.talents.length && initialValues?.talents.length > 0 ?
                        `This skill will also be removed from ${initialValues.talents.length} ${initialValues.talents.length === 1 ? `${talentTerminology.toLowerCase()}'s` : talentTerminologyPlural.toLowerCase()} profile, and from any ${gigTerminologyPlural.toLowerCase()} that have listed it as a requirement.`
                        : `This skill will also be removed from any ${gigTerminologyPlural.toLowerCase()} that have listed it as a requirement.`}
                confirmButtonText="Delete"
            />
        </>
    );
};

export default SkillEditForm;