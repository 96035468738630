import { DeleteForeverOutlined } from "@mui/icons-material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useAppPaths } from "../../Routes";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import FormCard from "../FormCard";
import FormErrors from "../FormErrors";
import FormTextInput from "../FormTextInput";
import FormInputLabel from "../FormInputLabel";
import { LocationFormValues, useLocationForm } from "./LocationFormValues";
import { LocationDetailsDto } from "../../api/models/location";

export type LocationEditFormProps = {
    onSubmit: (values: LocationFormValues) => Promise<GigApiFetcherResponse<void>>
    onDelete: () => Promise<GigApiFetcherResponse<void>>
    initialValues: LocationDetailsDto
    isDeleting: boolean
    isSubmitting: boolean
}

const LocationEditForm = ({
    onSubmit,
    onDelete,
    initialValues,
    isDeleting,
    isSubmitting
}: LocationEditFormProps) => {
    const appPaths = useAppPaths();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const navigate = useNavigate();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.locations.index);
        }
    });
    const [openDeleteDialog, deleteDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleDelete();
        }
    });

    const methods = useLocationForm({
        ...initialValues,
    });

    useEffect(() => {
        methods.reset({
            ...initialValues,
        });
    }, [methods.reset, initialValues]);

    const {
        formState: { isDirty },
    } = methods;

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.locations.index);
        }
    };

    const handleSubmit = async (values: LocationFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) {
            setSubmissionError(response.error);
        }
    };


    const handleDelete = async () => {
        setSubmissionError(undefined);

        const response = await onDelete();

        if (!response.success) {
            setSubmissionError(response.error);
        }
        else navigate(appPaths.locations.index);
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title="Edit Location"
                    >
                        <Box sx={{
                            minHeight: "25rem",
                            display: "flex",
                            flexDirection: "column",
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            },
                        }}>
                            <Box className="space-y-2">
                                <FormInputLabel>Name</FormInputLabel>
                                <FormTextInput
                                    multiline
                                    name="name"
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <Box className="space-y-2">
                                <FormInputLabel>Country code</FormInputLabel>
                                <FormTextInput
                                    multiline
                                    name="countryCode"
                                    disabled={isSubmitting}
                                />
                            </Box>
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "flex-end", marginTop: "auto", gap: 2 } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "auto" }
                        }>
                            <LoadingButton disabled={isDeleting || isSubmitting} startIcon={<DeleteForeverOutlined sx={isDeleting ? { opacity: "26%" } : { opacity: "100%" }} />} loading={isDeleting} color="secondary" onClick={openDeleteDialog}>Delete</LoadingButton>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                disabled={!isDirty}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Save changes
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            <ConfirmDialogBox
                {...deleteDialogProps}
                message={"Are you sure you want to delete this location? This action cannot be undone."}
                confirmButtonText="Delete"
            />
        </>
    );
};

export default LocationEditForm;
