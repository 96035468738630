import { useState } from "react";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CreatedByDisplay from "../../components/CreatedByDisplay";
import FormCard from "../../components/FormCard";
import EditButton from "../EditButton";
import Loader from "../../components/Loader";
import { useOrganizationConfig } from "../../api/organization";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { AssignRoleDto, RoleDetailsDto, roleAssignmentTypeOptions, roleAssignmentTypes, roleStatusOptions, roleStatuses, roleVisibilityOptions } from "../../api/models/role";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useUserGetMe } from "../../api/userGetMe";
import useCurrentUser from "../../hooks/useCurrentUser";
import ApiError from "../../api/common/apiError";
import useDialogBoxState from "../DialogBox/useDialogBoxState";
import { mapToAssignRoleDto } from "../../mappers/formMappers/roleAssignment";
import { AssignRoleFormValues, useAssignRoleForm } from "./AssignRoleFormValues";
import AssignRoleFormDialog from "./AssignRoleFormDialog";
import TalentDisplay from "../ClientEditForm/TalentDisplay";
import { useRoleAssignments } from "../../api/roleAssignments";
import { roleAssignmentStatusOptions, roleAssignmentStatusTypes } from "../../api/models/roleAssignment";

export type RoleViewPageProps = {
    role: RoleDetailsDto
    loading: boolean
    handleCloseButton: () => void
    handleEditRole: () => void
    onAssign: (values: AssignRoleDto) => Promise<GigApiFetcherResponse<void> | undefined>
}

const RoleViewPage = ({
    role,
    loading,
    handleCloseButton,
    handleEditRole,
    onAssign,
}: RoleViewPageProps) => {
    const theme = useTheme();
    const { giggedClientTerminology, talentTerminologyPlural } = useOrganizationConfig();
    const isLargeScreen = useIsLargeScreen();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const methods = useAssignRoleForm();
    const assignDialogBoxState = useDialogBoxState();
    const roleAssignments = useRoleAssignments();

    const roleIsEditable = [roleStatuses.draft, roleStatuses.open].includes(role.roleStatusId);
    const roleIsOpen = role.roleStatusId === roleStatuses.open || (role.roleStatusId === roleStatuses.active && role.allowMultipleHires);
    const { isClient, idpUserId } = useCurrentUser();
    const { clientId } = useUserGetMe();
    const isAdminOnly = idpUserId.length > 0 && !isClient;
    const roleIsAssignable = (roleIsOpen && role.roleAssignmentTypeId === roleAssignmentTypes.assignment) && (isAdminOnly || (isClient && role.giggedClientId === clientId));
    
    const handleAssign = async (values: AssignRoleFormValues) => {
        setSubmissionError(undefined);

        const assignRoleDto = mapToAssignRoleDto(values, { giggedClientId: role.giggedClientId });
        const response = await onAssign(assignRoleDto);

        if (response && response.success) {
            assignDialogBoxState.close();
            methods.reset();
        } else {
            setSubmissionError(response?.error);
        }
    };

    const pendingRoleAssignments = roleAssignments.roleAssignments
        .filter(assignment => assignment.roleId === role.id)
        .filter(assignment => [roleAssignmentStatusTypes.submitted, roleAssignmentStatusTypes.pending].includes(assignment.roleAssignmentStatusId));

    const handleAssignDialogClose = () => {
        methods.reset();
        setSubmissionError(undefined);
        assignDialogBoxState.close();
    };

    if (!role) return <Loader />;

    return (
        <>
            <FormCard
                title={role.title}
                onClose={handleCloseButton}
            >
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        ID: <Typography sx={{ marginLeft: "8px" }} component="span">{role.id}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Role Category: <Typography sx={{ marginLeft: "8px" }} component="span">{role.roleCategoryName}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {giggedClientTerminology}: <Typography sx={{ marginLeft: "8px" }} component="span">{role.giggedClientName}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Description: <Typography sx={{ marginLeft: "8px" }} component="span">{role.description}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Status: <Typography sx={{ marginLeft: "8px" }} component="span">{roleStatusOptions[role.roleStatusId].label}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Visibility: <Typography sx={{ marginLeft: "8px" }} component="span">{roleVisibilityOptions[role.roleVisibilityTypeId].label}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Assignment type: <Typography sx={{ marginLeft: "8px" }} component="span">{roleAssignmentTypeOptions[role.roleAssignmentTypeId].label}</Typography>
                    </Typography>
                    <CreatedByDisplay
                        createdBy={role.createdByUserName}
                        createdAt={role.createdAt}
                        updatedBy={role.lastUpdatedByUserName}
                        updatedAt={role.lastUpdatedAt}
                    />
                    <Typography fontWeight="bold" component="label">
                        Skills:
                    </Typography>
                    <div className="-mt-2 -mr-2 child:mr-2 child:mt-2 pt-2">
                        {role.skills.map((skill) => (
                            <Chip
                                key={skill.id}
                                label={skill.name}
                                disabled={loading}
                                variant="outlined"
                                color="secondary"
                            />
                        ))}
                    </div>
                    <div className="flex flex-col space-y-4">
                        <Typography fontWeight="bold" component="label" >
                            Hired {talentTerminologyPlural.toLowerCase()}:
                        </Typography>

                        {role.hiredApplications?.length > 0 ? (
                            role.hiredApplications.map(talent => (
                                <TalentDisplay key={talent.talentId} talentId={talent.talentId} talentName={`${talent.talentFirstName} ${talent.talentLastName}`} />
                            ))
                        ) : null}

                        {pendingRoleAssignments?.length > 0 ? (
                            pendingRoleAssignments.map(assignment => (
                                <TalentDisplay key={assignment.talentId} talentId={assignment.talentId} talentName={assignment.talentName} status={`${roleAssignmentStatusOptions[assignment.roleAssignmentStatusId].label}`} />
                            ))
                        ) : null}
                        
                        {!role.hiredApplications || role.hiredApplications.length === 0 
                            && !pendingRoleAssignments || pendingRoleAssignments.length === 0 
                            ? <span>None yet</span> 
                            : null}
                    </div>

                    <Box sx={
                        isLargeScreen ?
                            { display: "flex", marginTop: 6, gap: 2, flexDirection: "row-reverse" } :
                            { display: "flex", marginTop: 6, flexDirection: "column", justifyContent: "flex-end" }
                    }>
                        {roleIsAssignable && (
                            <LoadingButton
                                disabled={loading}
                                loading={loading}
                                color="primary"
                                onClick={assignDialogBoxState.open}
                                variant="contained"
                            >
                                Assign role
                            </LoadingButton>
                        )}
                        {roleIsEditable && (
                            <EditButton
                                disabled={loading}
                                buttonText="Edit role"
                                onClick={handleEditRole}
                            />
                        )}
                    </Box>
                </Box>
            </FormCard >
            <AssignRoleFormDialog
                {...assignDialogBoxState}
                isOpen={assignDialogBoxState.isOpen}
                onClose={handleAssignDialogClose}
                handleAssign={handleAssign}
                loading={loading}
                methods={methods}
                error={submissionError?.userMessages}
            // TODO: disabledTalentIds={initialValues.talents.map(_ => _.id)}
            />
        </>
    );
};

export default RoleViewPage;