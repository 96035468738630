import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useLocationId } from "../Routes";
import { useLocation } from "../api/location";
import { LocationEditForm, LocationEditFormValues } from "../components/LocationEditForm";

const LocationEditPage = () => {
    const locationId = useLocationId();
    const location = useLocation<string>(locationId);

    const onSubmit = async (values: LocationEditFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await location.updateLocation({
            ...values,
        });

        if (response.success) {
            successToast("Location successfully updated.");
        }

        return response;
    };

    const handleDelete = async () => {
        const response = await location.deleteLocation();

        if (response.success) {
            successToast("Location successfully deleted.");
        }

        return response;
    };

    if (location.isLoading || !location.location) return <Loader />;

    return (
        <FormContainer>
            <LocationEditForm
                initialValues={location.location}
                onSubmit={onSubmit}
                onDelete={handleDelete}
                isSubmitting={location.isUpdatingLocation}
                isDeleting={location.isDeletingLocation}
            />
        </FormContainer>
    );
};

export default LocationEditPage;
