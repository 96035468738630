import React, { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { GigSummary } from "./models/gig";

export const useDeleteGig = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (gigId) => ({
        path: `api/gigs/${gigId}`,
    }));

export type UseGigsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<GigSummary>>, "data"> & {
        gigs: GigSummary[]
        orderBy: string
        sortOrder:  SortOrder
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        setGigStatusIds: (statusIds: number[]) => void
        setComplianceCheckFilter: (complianceCheckFilter?: boolean) => void
        deleteGig: (gigId: string) => Promise<GigApiFetcherResponse<void>>
        isDeletingGig: boolean
    };

export const useGigs = (initialPageSize = 10): UseGigsReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("CreatedAt");
    const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [gigStatusIds, setGigStatusIds] = useState<number[]>([]);
    const [complianceCheckFilter, setComplianceCheckFilter] = useState<boolean | undefined>();

    const [deleteGig, isDeletingGig] = useDeleteGig();

    const url = "api/gigs?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&` +
        (gigStatusIds.length > 0 ? `&gigStatusIds=${gigStatusIds.join(",")}&` : "") +
        (complianceCheckFilter === undefined ? "" : `isComplianceCheckRequired=${complianceCheckFilter}`);
        

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<GigSummary>>(url);
    
    return {
        ...apiCall,
        ...apiCall.data,
        gigs: apiCall.data?.items || [],
        orderBy,
        sortOrder,
        setSearchTerm,
        setOrderBy,
        setSortOrder,
        setPageIndex,
        setPageSize,
        setGigStatusIds,
        setComplianceCheckFilter,
        deleteGig: async (gigId: string) => {
            const response = await deleteGig(gigId);

            if (response.success) apiCall.mutate();

            return response;
        },
        isDeletingGig,
    };
};