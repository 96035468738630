import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import { useUsers } from "../../api/users";
import Loader from "../Loader";
import Table from "../Table";
import TableChipCell from "../Table/TableChipCell";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import formatDateOnlyString from "../../utils/formatDateString";
import formatDateTimeString from "../../utils/formatDateTimeString";

export type UsersTableProps = {
    searchTerm?: string
    isAdminFilter?: boolean
    isArchivedFilter?: boolean
}

const UsersTable = ({
    searchTerm,
    isAdminFilter,
    isArchivedFilter,
}: UsersTableProps) => {
    const users = useUsers();
    const { giggedClientTerminology, talentTerminology } = useOrganizationConfig();

    const handleOrderByChange = (property: string) => {
        const isAsc = users.orderBy === property && users.sortOrder === "asc";
        users.setSortOrder(isAsc ? "desc" : "asc");
        users.setOrderBy(property);
    };

    const setSearchTerm = users.setSearchTerm;
    const setIsAdminFilter = users.setIsAdminFilter;
    const setIsArchivedFilter = users.setIsArchivedFilter;

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    useEffect(() => {
        setIsAdminFilter(isAdminFilter);
    }, [setIsAdminFilter, isAdminFilter]);

    useEffect(() => {
        setIsArchivedFilter(isArchivedFilter);
    }, [setIsArchivedFilter, isArchivedFilter]);

    if (users.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "FirstName",
                        label: "First name",
                        isSortable: true,
                    },
                    {
                        key: "LastName",
                        label: "Last name",
                        isSortable: true,
                    },
                    {
                        key: "Email",
                        label: "Email",
                        isSortable: true,
                    },
                    {
                        key: "MemberSince",
                        label: "Date registered",
                        isSortable: true,
                    },
                    {
                        key: "LastActivityDate",
                        label: "Last active",
                        isSortable: true,
                    },
                    {
                        label: "User type",
                        isSortable: false,
                    },
                    {
                        label: "Is active",
                        isSortable: false,
                    },
                    {
                        label: "",
                        isSortable: false,
                    },
                ]}
                data={users.data}
                renderRow={user => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{user.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{user.firstName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{user.lastName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{user.email}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(user.memberSince)}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{user.lastActivityDate ? formatDateTimeString(user.lastActivityDate) : "-"}</TableCell>
                        <TableChipCell 
                            labels={[
                                user.talentId ? talentTerminology : user.giggedClientId ? giggedClientTerminology : "",
                                user.isAdmin ? "Admin" : ""
                            ]} 
                        />
                        <TableChipCell labels={[user.isArchived ? "" : "Active"]} />
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={`/users/${user.id}`}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={users.setPageIndex}
                setPageSize={users.setPageSize}
                orderBy={users.orderBy}
                sortOrder={users.sortOrder}
                pageSize={users.pageSize}
                pageIndex={users.pageIndex}
                totalCount={users.totalCount}
                isLoading={users.isLoading}
            />
        </TableCard>
    );
};

export default UsersTable;