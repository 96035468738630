import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type TalentEditFormValues = {
    agencyId?: string | null
};

export const useEditTalentForm = (initialValues?: TalentEditFormValues) => {
    const schema = talentValidationSchema();

    return useForm<TalentEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: initialValues
    });
};

const talentValidationSchema = () => {
    return yup
        .object({
            agencyId: yup.string()
        })
        .required();
};