import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Divider, Fab, ListItemText, MenuItem, MenuList, useMediaQuery, useTheme, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "@mui/icons-material/FilterList";
import { useDebounce } from "use-debounce";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useAppPaths } from "../../Routes";
import { IconButtonMenu, MenuCheckboxGroup } from "../Menu";
import SearchBox from "../SearchBox";
import DeletableChips from "../DeletableChips";
import UsersTable from "./UsersTable";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";

const UsersTableWithSearch = () => {
    const appPaths = useAppPaths();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [includeAdmins, setIncludeAdmins] = useState(false);
    const [includeNonAdmins, setIncludeNonAdmins] = useState(false);
    const [includeArchived, setIncludeArchived] = useState(false);
    const [includeActive, setIncludeActive] = useState(false);
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);

    const isAdminFilter = includeAdmins === includeNonAdmins ? undefined : includeAdmins;
    const isArchivedFilter = includeArchived === includeActive ? undefined : includeArchived;

    const filterChips = (
        <DeletableChips
            chips={[
                {
                    label: "Admin",
                    onDelete: () => setIncludeAdmins(false),
                    visible: includeAdmins,
                },
                {
                    label: "Non-admin",
                    onDelete: () => setIncludeNonAdmins(false),
                    visible: includeNonAdmins,
                },
                {
                    label: "Archived",
                    onDelete: () => setIncludeArchived(false),
                    visible: includeArchived,
                },
                {
                    label: "Active",
                    onDelete: () => setIncludeActive(false),
                    visible: includeActive,
                },
            ]}
            sx={{
                color: `${theme.palette.tertiary.main} !important`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
            }}
            deleteIcon={<CloseRoundedIcon />}
        />
    );

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                "> *:not(:first-of-type)": {
                    marginLeft: 1,
                },
            }}>
                <SearchBox
                    placeholder={isLargeScreen ? "Search by First name, Last name or Email" : "Search Users"}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={showFilterChipsInline ? { minWidth: "28rem", background: "#FFFFFF" } : { flexGrow: 1, background: "#FFFFFF" }}
                />
                {showFilterChipsInline && (
                    <>
                        {filterChips}
                        <Box sx={{ flexGrow: 1 }} />
                    </>
                )}
                <IconButtonMenu
                    id="filter-users"
                    icon={isLargeScreen ? <FilterIcon sx={{ color: theme.palette.secondary.main}} fontSize="large" /> : <MoreVertIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />}

                >
                    <MenuList sx={{ width: "17rem", padding: 0 }}>
                        <MenuCheckboxGroup
                            label="Is admin"
                            options={[
                                {
                                    label: "Yes",
                                    value: includeAdmins,
                                    onChange: setIncludeAdmins,
                                },
                                {
                                    label: "No",
                                    value: includeNonAdmins,
                                    onChange: setIncludeNonAdmins,
                                },
                            ]}
                        />
                        <Divider />
                        <MenuCheckboxGroup
                            label="Is active"
                            options={[
                                {
                                    label: "Yes",
                                    value: includeActive,
                                    onChange: setIncludeActive,
                                },
                                {
                                    label: "No",
                                    value: includeArchived,
                                    onChange: setIncludeArchived,
                                },
                            ]}
                        />
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setIncludeAdmins(false);
                                setIncludeNonAdmins(false);
                                setIncludeArchived(false);
                                setIncludeActive(false);
                            }}
                        >
                            <ListItemText sx={{ textAlign: "right" }}>
                                Clear filters
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </IconButtonMenu>

                {isLargeScreen && (
                    <Link to={appPaths.users.create} style={{ textDecoration: "none", outline: "none", marginLeft: "1.25rem" }}>
                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        > 
                            Create 
                        </Button>
                    </Link>
                )}
            </Box>
            {!showFilterChipsInline && (
                <Box sx={{
                    marginTop: "1.5rem"
                }}>
                    {filterChips}
                </Box>
            )}
            <Box sx={{ marginTop: "1.5rem" }}>
                <UsersTable
                    searchTerm={debounceSearchTerm}
                    isAdminFilter={isAdminFilter}
                    isArchivedFilter={isArchivedFilter}
                />
            </Box>
            {!isLargeScreen && (
                <Link to={appPaths.users.create}>
                    <Fab
                        color="primary"
                        aria-label="create-user"
                        sx={{
                            position: "fixed",
                            bottom: theme.spacing(10),
                            right: theme.spacing(4),
                        }}
                    >
                        <AddIcon fontSize="large" />
                    </Fab>
                </Link>
            )}
        </>
    );
};

export default UsersTableWithSearch;