import { ApiRoleAssignmentDetailsDto, ApiRoleAssignmentSummaryDto } from "../api/models/api/roleAssignment";
import { RoleAssignmentDetailsDto, RoleAssignmentSummaryDto } from "../api/models/roleAssignment";

export const mapFromApiRoleAssignmentSummaryDto = (apiDto: ApiRoleAssignmentSummaryDto): RoleAssignmentSummaryDto => ({
    ...apiDto,
    id: apiDto.roleAssignmentId
});

export const mapFromApiRoleAssignmentDetailsDto = (apiDto: ApiRoleAssignmentDetailsDto): RoleAssignmentDetailsDto => ({
    ...apiDto,
    id: apiDto.roleAssignmentId
});