import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import Loader from "../Loader";
import Table from "../Table";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import formatDateTimeString from "../../utils/formatDateTimeString";
import { useAppPaths } from "../../Routes";
import { useLocations } from "../../api/locations";

export type LocationsTableProps = {
    searchTerm?: string
}

const LocationsTable = ({
    searchTerm,
}: LocationsTableProps) => {
    const locations = useLocations();
    const { talentTerminology, gigTerminology, giggedClientTerminology } = useOrganizationConfig();
    const appPaths = useAppPaths();

    const handleOrderByChange = (property: string) => {
        const isAsc = locations.orderBy === property && locations.sortOrder === "asc";
        locations.setSortOrder(isAsc ? "desc" : "asc");
        locations.setOrderBy(property);
    };

    const setSearchTerm = locations.setSearchTerm;

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    if (locations.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "Name",
                        label: "Name",
                        isSortable: true,
                    },
                    {
                        key: "TalentCount",
                        label: `${talentTerminology} count`,
                        isSortable: true,
                    },
                    {
                        key: "GiggedClientsCount",
                        label: `${giggedClientTerminology} count`,
                        isSortable: true,
                    },
                    {
                        key: "GigsCount",
                        label: `${gigTerminology} count`,
                        isSortable: true,
                    },
                    {
                        key: "CreatedAt",
                        label: "Created at",
                        isSortable: true,
                    },
                    {
                        label: "",
                        isSortable: false
                    }
                ]}
                data={locations.locations}
                renderRow={location => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{location.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{location.name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{location.talentsCount}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{location.giggedClientsCount}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{location.gigsCount}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateTimeString(location.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.locations.edit(location.id)}>
                                <IconButton aria-label="edit-location" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={locations.setPageIndex}
                setPageSize={locations.setPageSize}
                orderBy={locations.orderBy}
                sortOrder={locations.sortOrder}
                pageSize={locations.pageSize}
                pageIndex={locations.pageIndex}
                totalCount={locations.totalCount}
                isLoading={locations.isLoading}
            />
        </TableCard>
    );
};

export default LocationsTable;