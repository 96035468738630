import ApiError from "./common/apiError";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { RoleCategoryDetailsDto, UpdateRoleCategoryDto } from "./models/roleCategory";

export const useUpdateRoleCategory = () => useAuthenticatedGigApiFetcher<UpdateRoleCategoryDto, void, { roleCategoryId: string, dto: UpdateRoleCategoryDto }>("PUT",
    ({ roleCategoryId, dto }) => ({
        path: `api/roles/categories/${roleCategoryId}`,
        body: dto
    }));

export type UseRoleCategoryReturn<TRoleCategoryId extends string | undefined> = TRoleCategoryId extends undefined ?
    { roleCategory: undefined } :
    {
        roleCategory?: RoleCategoryDetailsDto
        isLoading: boolean
        error?: ApiError
        updateRoleCategory: (dto: UpdateRoleCategoryDto) => Promise<GigApiFetcherResponse<void>>
        deleteRoleCategory: () => Promise<GigApiFetcherResponse<void>>
        isUpdatingRoleCategory: boolean
    }

export const useRoleCategory = <TRoleCategoryId extends string | undefined>(roleCategoryId: TRoleCategoryId): UseRoleCategoryReturn<TRoleCategoryId> => {
    const url = `api/roles/categories/${roleCategoryId}`;

    const apiCall = useGiggedApiSWR<RoleCategoryDetailsDto>(url);

    const [updateRoleCategory, isUpdatingRoleCategory] = useUpdateRoleCategory();

    if (roleCategoryId === undefined) return { roleCategory: undefined } as UseRoleCategoryReturn<TRoleCategoryId>;

    return {
        roleCategory: apiCall.data,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateRoleCategory: async (dto: UpdateRoleCategoryDto) => {
            const response = await updateRoleCategory({ roleCategoryId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        isUpdatingRoleCategory,
    } as UseRoleCategoryReturn<TRoleCategoryId>;
};