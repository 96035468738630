import { Box, Typography } from "@mui/material";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import SkillCategoriesTable from "../components/SkillCategoriesTable";

const SkillCategoriesListPage = () => {
    const isLargeScreen = useIsLargeScreen();

    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 )}}>
                <Typography component="h1" variant="h4" gutterBottom>Skill Categories</Typography>
                <SkillCategoriesTable />
            </Box>
        </>
    );
};

export default SkillCategoriesListPage;