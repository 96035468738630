import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type ClientAddTalentFormValues = {
    talentId: string
};

export const useAddTalentForm = () => {
    const schema = addTalentValidationSchema();
    
    return useForm<ClientAddTalentFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange"
    });
};

const addTalentValidationSchema = () => {
    return yup
        .object({
            talentId: yup.string().required("Talent is required."),
        })
        .required();
};