import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Button, MenuItem, Select } from "@mui/material";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";

import { expectedDurationOptions, GigSummary, initialStageOptions } from "../../api/models/gig";
import { useOrganizationConfig } from "../../api/organization";
import FormCard from "../FormCard";
import FormDateInput from "../FormDateInput";
import FormInputLabel from "../FormInputLabel";
import FormTextInput from "../FormTextInput";
import SkillsInput from "../SkillsInput";
import { GigEditFormValues, useEditGigForm } from "./GigEditFormValues";
import Loader from "../Loader";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import FormErrors from "../FormErrors";
import ApiError from "../../api/common/apiError";
import { addDays } from "date-fns";

export type GigEditFormProps = {
    gig: GigSummary
    loading: boolean
    submissionError?: ApiError
    handleSaveChanges: (values: GigEditFormValues) => Promise<void>
    handleCloseEditGigPage: () => void
}

const GigEditForm = ({
    gig,
    loading,
    submissionError,
    handleSaveChanges,
    handleCloseEditGigPage
}: GigEditFormProps) => {
    const [gigLoaded, setGigLoaded] = useState(false);
    const { gigTerminology } = useOrganizationConfig();
    const methods = useEditGigForm(gig);
    const isLargeScreen = useIsLargeScreen();
    const { control, reset, watch, handleSubmit, formState, formState: { dirtyFields } } = methods;
    const isDirtyAlt = Object.keys(dirtyFields).length > 0;
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleCloseEditGigPage();
        }
    });

    useEffect(() => {
        if (gigLoaded) return;

        reset(gig);

        if (gig) {
            setGigLoaded(true);
        }

    }, [gig, reset, gigLoaded]);

    const handleDiscardEditGig = () => {
        if (isDirtyAlt) {
            openCancelDialog();
        } else {
            handleCloseEditGigPage();
        }
    };

    const { append: appendSkill, remove: removeSkill } = useFieldArray<GigEditFormValues, "skills">({
        control: control,
        name: "skills",
        keyName: "id",
    });

    const { skills } = watch();

    if (!gig) return <Loader />;

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(handleSaveChanges)}
                    noValidate
                >
                    <FormCard
                        title={`Edit ${gigTerminology}`}
                    >
                        <Box className="space-y-4">
                            <Box className="space-y-2">
                                <FormInputLabel required>{`${gigTerminology} title`}</FormInputLabel>
                                <FormTextInput
                                    required
                                    name="title"
                                />
                            </Box>
                            <Box className="space-y-2">
                                <FormInputLabel required>{`${gigTerminology} description`}</FormInputLabel>
                                <FormTextInput
                                    multiline
                                    required
                                    name="description"
                                />
                            </Box>
                            <Box className="md:space-x-8 space-y-4 md:space-y-0 flex flex-col md:flex-row">
                                <Box className="flex flex-col flex-1 space-y-2">
                                    <FormInputLabel required>Duration</FormInputLabel>
                                    <Controller
                                        name="expectedDurationId"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                value={value}
                                                onChange={onChange}
                                                variant="outlined"
                                                color="secondary"
                                            >
                                                {expectedDurationOptions.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </Box>
                                <Box className="flex flex-col flex-1 space-y-2">
                                    <FormInputLabel required>{`Stage of the ${gigTerminology.toLowerCase()}`}</FormInputLabel>
                                    <Controller
                                        name="initialStageId"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                value={value}
                                                onChange={onChange}
                                                variant="outlined"
                                                color="secondary"
                                            >
                                                {initialStageOptions.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box className="md:space-x-8 space-y-4 md:space-y-0 flex flex-col md:flex-row">
                                <Box className="flex flex-col flex-1">
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDateInput
                                                name="startDate"
                                                label="Start date"
                                                value={value}
                                                onChange={onChange}
                                                minDate={new Date()}
                                                error={formState.errors.startDate}
                                                required
                                            />
                                        )}
                                    />
                                </Box>
                                <Box className="flex flex-col flex-1">
                                    <Controller
                                        name="deadlineDate"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDateInput
                                                name="deadlineDate"
                                                label="Deadline (optional)"
                                                value={value}
                                                onChange={onChange}
                                                error={formState.errors.deadlineDate}
                                                minDate={addDays(methods.watch().startDate as Date, 1)}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            <SkillsInput
                                addSkill={appendSkill}
                                removeSkill={removeSkill}
                                skills={skills}
                                error={formState.errors.skills}
                                showError={formState.submitCount > 0}
                                maxSkills={3}
                                required
                            />
                            <FormErrors messages={submissionError?.userMessages} />

                            <Box className="flex flex-col-reverse md:flex-row md:justify-between md:items-center !mt-8">
                                <Button
                                    color="secondary"
                                    variant="text"
                                    disabled={loading}
                                    onClick={handleDiscardEditGig}
                                >
                                    Discard changes
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={!isDirtyAlt || loading ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    type="submit"
                                    color="primary"
                                    loading={loading}
                                    disabled={!isDirtyAlt}
                                    sx={!isLargeScreen ? { marginBottom: 2 } : { marginBottom: 0 }}
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default GigEditForm;