import { useNavigate } from "react-router-dom";

import { useUsers } from "../api/users";
import FormContainer from "../components/FormContainer";
import { UserCreateFormValues, UserCreateForm } from "../components/UserCreateForm";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast}  from "../toast";

const UserCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createUser, isCreatingUser } = useUsers();

    const onSubmit = async (values: UserCreateFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createUser({
            ...values,
            isAdmin: values.isAdmin === 1 ? true : false,
        });

        if (response.success) {
            successToast("User successfully created.");
            navigate(appPaths.users.index);
        }


        return response;
    };

    return (
        <FormContainer>
            <UserCreateForm onSubmit={onSubmit} isSubmitting={isCreatingUser} />
        </FormContainer>
    );
};

export default UserCreatePage;
