import { ApiTalentStatisticsDto } from "../../api/models/dashboardStatistics/talentStatistics";
import { useOrganizationConfig } from "../../api/organization";
import { dashboardOverviewTooltips } from "../../tooltipsContent";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashboardSection from "../../components/Dashboard/DashboardSection";
import DashboardSubSection from "../../components/Dashboard/DashboardSubSection";
import { useSkills } from "../../api/skills";
import { Link } from "react-router-dom";
import { useAppPaths } from "../../Routes";

export type DashboardTalentSectionProps = {
    statistics?: ApiTalentStatisticsDto
}

const DashboardTalentsSection = ({
    statistics
}: DashboardTalentSectionProps) => {
    const { talentTerminologyPlural } = useOrganizationConfig();
    const { skills } = useSkills();
    const appPaths = useAppPaths();

    return (
        <DashboardSection
            title={talentTerminologyPlural}
        >
            <div className="flex flex-col space-y-2 w-full">
                <DashboardCard
                    title={`Total ${talentTerminologyPlural.toLowerCase()}`}
                    statistic={statistics?.createdTotal}
                    tooltip={dashboardOverviewTooltips.total(talentTerminologyPlural)}
                />
                <DashboardSubSection
                    title={`New ${talentTerminologyPlural.toLowerCase()}`}
                    tooltip={dashboardOverviewTooltips.created(talentTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={`${statistics?.createdLast7Days || 0} (+${statistics?.percentageIncreaseLast7Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={`${statistics?.createdLast14Days || 0} (+${statistics?.percentageIncreaseLast14Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={`${statistics?.createdLast30Days || 0} (+${statistics?.percentageIncreaseLast30Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={`${statistics?.createdLast3Months || 0} (+${statistics?.percentageIncreaseLast3Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={`${statistics?.createdLast6Months || 0} (+${statistics?.percentageIncreaseLast6Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={`${statistics?.createdLast9Months || 0} (+${statistics?.percentageIncreaseLast9Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={`${statistics?.createdLast12Months || 0} (+${statistics?.percentageIncreaseLast12Months || 0}%)`}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title={`Active ${talentTerminologyPlural.toLowerCase()}`}
                    tooltip={dashboardOverviewTooltips.lastActive(talentTerminologyPlural)}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={statistics?.activeLast7Days || 0}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={statistics?.activeLast14Days || 0}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={statistics?.activeLast30Days || 0}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={statistics?.activeLast3Months || 0}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={statistics?.activeLast6Months || 0}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={statistics?.activeLast9Months || 0}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={statistics?.activeLast12Months || 0}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title={`${talentTerminologyPlural} by location`}
                    tooltip={dashboardOverviewTooltips.locations(talentTerminologyPlural)}
                >
                    {statistics?.talentsLocationsByCount && statistics?.talentsLocationsByCount.length ? (
                        <div className="flex flex-row space-x-2">
                            {statistics.talentsLocationsByCount.map(location => (
                                <DashboardCard
                                    key={location.locationName}
                                    title={location.locationName}
                                    statistic={location.locationCount || 0}
                                />
                            ))}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
                <DashboardSubSection
                    title={`${talentTerminologyPlural} by skill`}
                    tooltip={dashboardOverviewTooltips.talentSkills(talentTerminologyPlural)}
                >
                    {statistics?.talentsSkillsByCount && statistics?.talentsSkillsByCount.length ? (
                        <div className="flex flex-row space-x-2 items-stretch">
                            {skills.sort((a, b) => b.talentsCount - a.talentsCount).map(skill => (
                                <Link to={`${appPaths.talents.skill(skill.id)}?skillName=${skill.name}`} className="items-stretch flex">
                                    <DashboardCard
                                        key={skill.id}
                                        title={skill.name}
                                        statistic={skill.talentsCount}
                                    />
                                </Link>
                            ))}
                            {/* {statistics.talentsSkillsByCount.map(skill => (
                                <DashboardCard
                                    key={skill.skillName}
                                    title={skill.skillName}
                                    statistic={skill.skillCount}
                                />
                            ))} */}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
                <DashboardSubSection
                    title={`${talentTerminologyPlural} by specialisation`}
                    tooltip={dashboardOverviewTooltips.talentSpecializations(talentTerminologyPlural)}
                >
                    {statistics?.talentsSpecializationsByCount && statistics?.talentsSpecializationsByCount.length ? (
                        <div className="flex flex-row space-x-2">
                            {statistics.talentsSpecializationsByCount.map(specialization => (
                                <DashboardCard
                                    key={specialization.specializationName}
                                    title={specialization.specializationName}
                                    statistic={specialization.specializationCount}
                                />
                            ))}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
            </div>
        </DashboardSection>
    );
};

export default DashboardTalentsSection;