import { useState } from "react";
import { Fab, Box, Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useDebounce } from "use-debounce";

import { useAppPaths } from "../../Routes";
import SearchBox from "../SearchBox";
import ClientsTable from "./ClientsTable";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useOrganizationConfig } from "../../api/organization";

const ClientsTableWithSearch = () => {
    const appPaths = useAppPaths();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);
    const { giggedClientTerminology } = useOrganizationConfig();

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                justifyContent: "space-between",
                "& > *:not(:first-of-type)": {
                    marginLeft: 1,
                },
            }}>
                <SearchBox
                    placeholder={`Search by ${giggedClientTerminology} name`}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={isLargeScreen ? { minWidth: "28rem", background: "#FFFFFF" } : { flexGrow: 1, background: "#FFFFFF" }}
                />

                {isLargeScreen && (
                    <Link to={appPaths.clients.create} tabIndex={-1} style={{ textDecoration: "none", outline: "none", marginLeft: "1.25rem" }}>
                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            Create
                        </Button>
                    </Link>
                )}
            </Box>
        
            <Box sx={{ marginTop: "1.5rem" }}>
                <ClientsTable
                    searchTerm={debounceSearchTerm}
                />
            </Box>
            {!isLargeScreen && (
                <Link to={appPaths.clients.create}>
                    <Fab
                        color="primary"
                        aria-label="create-client"
                        sx={{
                            position: "fixed",
                            bottom: theme.spacing(10),
                            right: theme.spacing(4),
                        }}
                    >
                        <AddIcon fontSize="large" />
                    </Fab>
                </Link>
            )}
        </>
    );
};
export default ClientsTableWithSearch;