import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useOrganizationConfig } from "../../api/organization";
import defaultIfUndefined from "../../utils/defaultIfUndefined";

export type RoleEditFormValues = {
    title: string
    description: string
    roleCategoryId: string
    giggedClientId: string
    skills: { id: string, name: string }[],
    roleStatusId: number
    roleVisibilityTypeId: number
    roleAssignmentTypeId: number
};

export const useRoleEditForm = (initialValues: RoleEditFormValues) => {
    const schema = roleValidationSchema();

    return useForm<RoleEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            ...initialValues,
            roleVisibilityTypeId: defaultIfUndefined(initialValues.roleVisibilityTypeId, -1),
            roleAssignmentTypeId: defaultIfUndefined(initialValues.roleAssignmentTypeId, -1)
        }
    });
};

const roleValidationSchema = () => {
    const { giggedClientTerminology } = useOrganizationConfig();

    return yup
        .object({
            title: yup.string().required("Role title is required."),
            description: yup.string().required("Description is required."),
            roleCategoryId: yup.string().required("Role category is required."),
            giggedClientId: yup.string().required(`${giggedClientTerminology} is required.`),
            skills: yup
                .array()
                .min(3, "You must select a minimum of 3 skills")
                .of(
                    yup.object().shape({
                        id: yup.string(),
                        name: yup.string(),
                    }),
                ),
            roleVisibilityTypeId: yup.number().required("Role visibility is required.").min(0, "Role visibility is required."),
            roleAssignmentTypeId: yup.number().required("Role assignment type is required.").min(0, "Role assignment type is required."),
        })
        .required();
};