import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { LocationDetailsDto, LocationSummaryDto, UpdateLocationDto } from "./models/location";
import ApiError from "./common/apiError";

export const useUpdateLocation = () => useAuthenticatedGigApiFetcher<UpdateLocationDto, void, { locationId: string, dto: UpdateLocationDto }>("PUT",
    ({ locationId, dto }) => ({
        path: `api/locations/${locationId}`,
        body: dto
    }));

export const useDeleteLocation = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (locationId) => ({
        path: `api/locations/${locationId}`,
    }));

export type UseLocationReturn<TLocationId extends string | undefined> = TLocationId extends undefined ?
    { location: undefined } :
    {
        location?: LocationDetailsDto
        isLoading: boolean
        error?: ApiError
        updateLocation: (dto: UpdateLocationDto) => Promise<GigApiFetcherResponse<void>>
        deleteLocation: () => Promise<GigApiFetcherResponse<void>>
        isUpdatingLocation: boolean
        isDeletingLocation: boolean
    }

export const useLocation = <TLocationId extends string | undefined>(locationId: string): UseLocationReturn<TLocationId> => {
    const url = `api/locations/${locationId}`;
    const apiCall = useGiggedApiSWR<LocationSummaryDto>(url);

    const [updateLocation, isUpdatingLocation] = useUpdateLocation();
    const [deleteLocation, isDeletingLocation] = useDeleteLocation();

    if (locationId === undefined) return { location: undefined } as UseLocationReturn<TLocationId>;

    return {
        ...apiCall.data,
        location: apiCall.data,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateLocation: async (dto: UpdateLocationDto) => {
            const response = await updateLocation({
                locationId,
                dto
            });

            if (response.success) apiCall.mutate();

            return response;
        },
        deleteLocation: async () => {
            const response = await deleteLocation(locationId);

            return response;
        },
        isUpdatingLocation,
        isDeletingLocation
    } as UseLocationReturn<TLocationId>;
};