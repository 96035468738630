import FormContainer from "../components/FormContainer";
import { useApplicationId } from "../Routes";
import { successToast } from "../toast";
import { useApplication } from "../api/application";
import ApplicationViewPage from "../components/ApplicationView/ApplicationView";

const ApplicationPage = () => {
    const applicationId = useApplicationId();
    const { application, approveApplication, rejectApplication, isApprovingApplication, isRejectingApplication } = useApplication(applicationId);

    const handleApprove = async () => {
        const response = await approveApplication();

        if (response.success) successToast("Application approved.");

        return response;
    };

    const handleReject = async () => {
        const response = await rejectApplication();

        if (response.success) successToast("Application rejected.");

        return response;
    };
    
    return (
        <FormContainer>
            <ApplicationViewPage
                application={application}
                onApprove={handleApprove}
                onReject={handleReject}
                isApproving={isApprovingApplication}
                isRejecting={isRejectingApplication}
            />
        </FormContainer>
    );
};

export default ApplicationPage;