import { useAppPaths, useRoleAssignmentId } from "../Routes";
import { successToast } from "../toast";
import { useRoleAssignment } from "../api/roleAssignment";
import { RoleAssignmentViewPage } from "../components/RoleAssignmentView";
import FormContainer from "../components/FormContainer";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { RejectRoleAssignmentDto } from "../api/models/roleAssignment";


const RoleAssignmentPage = () => {
    const roleAssignmentId = useRoleAssignmentId();
    const navigate = useNavigate();
    const appPaths = useAppPaths();
    const { 
        roleAssignment, 
        approveRoleAssignment, 
        rejectRoleAssignment, 
        cancelRoleAssignment, 
        isApprovingRoleAssignment, 
        isRejectingRoleAssignment, 
        isCancellingRoleAssignment 
    } = useRoleAssignment(roleAssignmentId);

    const handleApprove = async () => {
        const response = await approveRoleAssignment();

        if (response.success) successToast("Role assignment approved.");

        return response;
    };
    
    const handleReject = async (values: RejectRoleAssignmentDto) => {
        const response = await rejectRoleAssignment(values);

        if (response.success) successToast("Role assignment rejected.");

        return response;
    };

    const handleCancel = async () => {
        const response = await cancelRoleAssignment({giggedClientId: roleAssignment?.giggedClientId});

        if (response.success) {
            successToast("Role assignment cancelled.");
            navigate(appPaths.roles.assignments.index);
        }

        return response;
    };

    if (!roleAssignment) return <Loader />;
    
    return (
        <FormContainer>
            <RoleAssignmentViewPage
                roleAssignment={roleAssignment}
                onApprove={handleApprove}
                onReject={handleReject}
                onCancel={handleCancel}
                isApproving={isApprovingRoleAssignment}
                isRejecting={isRejectingRoleAssignment}
                isCancelling={isCancellingRoleAssignment}
            />
        </FormContainer>
    );
};

export default RoleAssignmentPage;