import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type LocationFormValues = {
    name: string,
    countryCode: string,
};

export const useLocationForm = (initialValues?: Partial<LocationFormValues>) => {
    return useForm<LocationFormValues>({
        resolver: yupResolver(badgeValidationSchema),
        mode: "onSubmit",
        defaultValues: {
            ...initialValues,
        },
    });
};

const badgeValidationSchema = yup
    .object({
        name: yup.string().required("Country name is required."),
        countryCode: yup.string().required("Country code is required."),
    })
    .required();

