import { FieldError } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useState } from "react";

import { FormSearchDropdown } from "./FormSearchDropdown";
import { FormSearchDropdownOption, LoadOptionsCallback } from "./FormSearchDropdown/FormSearchDropdown";
import { fetchskillCategories } from "../api/skillCategories";
import useAccessToken from "../auth/useAccessToken";

export type SkillCategoryInputProps = {
    tooltip?: string
    value: string
    onChange: (value: string, option: FormSearchDropdownOption | null) => void
    isSubmitting: boolean
    error?: FieldError
}

export const SkillCategoryInput = ({
    tooltip,
    value,
    onChange,
    isSubmitting,
    error,
}: SkillCategoryInputProps) => {
    const accessToken = useAccessToken();

    const [allCategoryOptions, setAllCategoryOptions] = useState<FormSearchDropdownOption[]>([]);

    const fetchCategoryOptions = async (searchQuery: string) => {
        const categoriesResponse = await fetchskillCategories(searchQuery, accessToken);

        return categoriesResponse
            .map(category => ({
                value: category.id,
                label: category.name,
            }));
    };

    useEffect(() => {
        if (!accessToken) return;

        const loadAllCategories = async () => {
            const fetchedCategoryOptions = await fetchCategoryOptions("");
            setAllCategoryOptions(fetchedCategoryOptions);
        };

        loadAllCategories();
    }, [accessToken]);

    const loadCategoryOptions = (inputValue: string, callback: LoadOptionsCallback) => {
        fetchCategoryOptions(inputValue)
            .then(options => callback(options));
    };

    const debouncedLoadSkillOptions = useDebouncedCallback(loadCategoryOptions, 700);

    return (
        <>
            <FormSearchDropdown
                tooltip={tooltip}
                value={value}
                loadOptions={debouncedLoadSkillOptions}
                onChange={onChange}
                defaultOptions={allCategoryOptions}
                disabled={isSubmitting}
                required={true}
                error={error}
            />
        </>
    );
};