import { Box, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, Link } from "react-router-dom";

import FormCard from "../FormCard";
import Loader from "../Loader";
import { useAppPaths } from "../../Routes";
import CreatedByDisplay from "../CreatedByDisplay";
import ApiError from "../../api/common/apiError";
import { useState } from "react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import FormErrors from "../FormErrors";
import { useOrganizationConfig } from "../../api/organization";
import { ApplicationDetailsDto, applicationStatuses } from "../../api/models/application";
import { capitalizeStrings } from "../../utils/capitalizeString";                                                    
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";

export type ApplicationViewPageProps = {
    application: ApplicationDetailsDto
    onApprove: () => Promise<GigApiFetcherResponse<void>>
    onReject: () => Promise<GigApiFetcherResponse<void>>
    isApproving: boolean
    isRejecting: boolean
}

const ApplicationViewPage = ({
    application,
    onApprove,
    onReject,
    isRejecting,
    isApproving,
}: ApplicationViewPageProps) => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const { talentTerminology } = useOrganizationConfig();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [openRejectDialog, rejectDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => handleReject()
    });
    const [openAcceptDialog, acceptDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => handleApprove()
    });

    const handleCloseButton = () => {
        navigate(-1);
    };

    const handleApprove = async () => {
        setSubmissionError(undefined);

        const response = await onApprove();

        if (!response.success) setSubmissionError(response.error);
    };

    const handleReject = async () => {
        setSubmissionError(undefined);

        const response = await onReject();

        if (!response.success) setSubmissionError(response.error);
    };

    if (!application) return <Loader />;

    const applyingTalentName = `${application.talentFirstName} ${application.talentLastName}`;
    const applicationStatus = capitalizeStrings(Object.keys(applicationStatuses)[application.applicationStatusId]);

    return (
        <>
            <FormCard title={application.role.title} onClose={handleCloseButton}>
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <CreatedByDisplay createdAt={application.createdAt} updatedAt={application.lastUpdatedAt} />
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        ID: <Typography sx={{ marginLeft: "8px" }} component="span">{application.id}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {talentTerminology}: <Typography sx={{ marginLeft: "8px" }} component="span">{applyingTalentName}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Role: <Link className="ml-2 font-normal underline" to={appPaths.roles.view(application.role.id)}>{application.role.title}</Link>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Status: <Typography sx={{ marginLeft: "8px" }} component="span">{applicationStatus}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Portfolio URL: <a className="ml-2 cursor-pointer underline font-normal" href={application.portfolioUrl} target="blank">{application.portfolioUrl}</a>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Cover letter: <Typography sx={{ marginLeft: "8px" }} component="span">{application.coverLetter}</Typography>
                    </Typography>
                    <FormErrors messages={submissionError?.userMessages} />
                    {application.applicationStatusId === applicationStatuses.pending && (
                        <Box className="flex justify-between">
                            <LoadingButton
                                disabled={isRejecting}
                                loading={isRejecting}
                                color="secondary"
                                onClick={openRejectDialog}
                            >
                                Reject
                            </LoadingButton>
                            <LoadingButton
                                disabled={isApproving}
                                loading={isApproving}
                                color="secondary"
                                onClick={openAcceptDialog}
                            >
                                Approve
                            </LoadingButton>
                        </Box>
                    )}
                </Box>
            </FormCard>
            <ConfirmDialogBox
                {...rejectDialogProps}
                message="Are you sure you want to reject this application? This cannot be undone."
                confirmButtonText="Reject"
            />
            <ConfirmDialogBox
                {...acceptDialogProps}
                message="Are you sure you want to approve this application?"
                confirmButtonText="Approve"
            />
        </>
    );
};

export default ApplicationViewPage;