import { Routes, Route, Navigate, useParams } from "react-router-dom";
import ClientCreatePage from "./pages/ClientCreate";
import ClientsListPage from "./pages/ClientsList";
import ClientEditPage from "./pages/ClientEdit";
import GigsListPage from "./pages/GigsList";
import ProposalsListPage from "./pages/ProposalsList";
import DashboardPage from "./pages/DashboardStatistics/DashboardPage";
import UserCreatePage from "./pages/UserCreate";
import UserEditPage from "./pages/UserEdit";
import UsersListPage from "./pages/UsersList";
import TalentsListPage from "./pages/TalentsList";
import FourOFourPage from "./pages/404";
import GigPage from "./pages/GigPage";
import OrganizationSettingsPage from "./pages/OrganizationSettings";
import { useOrganizationConfig } from "./api/organization";
import SkillsListPage from "./pages/SkillsList";
import ProposalPage from "./pages/ProposalPage";
import MilestonesListPage from "./pages/ProposalMilestonesList";
import ProposalMilestonePage from "./pages/ProposalMilestonePage";
import ProposalMilestoneEditForm from "./components/ProposalMilestoneEdit/ProposalMilestoneEditForm";
import ReportsListPage from "./pages/ReportsList";
import ReportPage from "./pages/ReportPage";
import TalentEditPage from "./pages/TalentEdit";
import AgenciesListPage from "./pages/AgenciesList";
import AgencyEditPage from "./pages/AgencyEdit";
import AgencyCreatePage from "./pages/AgencyCreate";
import BadgesListPage from "./pages/BadgesList";
import BadgeCreatePage from "./pages/BadgeCreate";
import BadgeEditPage from "./pages/BadgeEdit";
import SkillCategoriesListPage from "./pages/SkillCategoriesList";
import SkillCategoryEditPage from "./pages/SkillCategoryEdit";
import SkillCategoryCreatePage from "./pages/SkillCategoryCreate";
import SkillCreatePage from "./pages/SkillCreate";
import SkillEditPage from "./pages/SkillEdit";
import RoleCategoriesListPage from "./pages/RoleCategoriesList";
import RoleCategoryEditPage from "./pages/RoleCategoryEdit";
import RoleCategoryCreatePage from "./pages/RoleCategoryCreate";
import RolesListPage from "./pages/RolesList";
import RoleCreatePage from "./pages/RoleCreate";
import RolePage from "./pages/RolePage";
import LocationsListPage from "./pages/LocationsList";
import LocationEditPage from "./pages/LocationEdit";
import LocationCreatePage from "./pages/LocationCreate";
import ApplicationsListPage from "./pages/ApplicationsList";
import ApplicationPage from "./pages/ApplicationPage";
import RoleAssignmentsListPage from "./pages/RoleAssignmentsList";
import RoleAssignmentPage from "./pages/RoleAssignmentPage";

export const fourOFour = "/404";

const appPaths = (gigTerminology: string, giggedTerminologyPlural: string, giggedClientTerminologyPlural: string, talentTerminologyPlural: string) => {
    const gig = gigTerminology.toLowerCase().replace(" ", "-");
    const gigPlural = giggedTerminologyPlural.toLowerCase().replace(" ", "-");
    const clientPlural = giggedClientTerminologyPlural.toLowerCase().replace(" ", "-");
    const talentPlural = talentTerminologyPlural.toLowerCase().replace(" ", "-");

    return ({
        index: "/",
        clients: {
            index: `/${clientPlural}`,
            create: `/${clientPlural}/create`,
            edit: (clientId: string) => `/${clientPlural}/${clientId}`,
        },
        gigs: {
            index: `/${gigPlural}`,
            view: (gigId: string) => `/${gigPlural}/${gigId}`,
            edit: (gigId: string) => `/${gigPlural}/${gigId}/edit`,
            proposals: (gigId: string) => `/proposals/${gig}/${gigId}`,
        },
        proposals: {
            index: "/proposals",
            view: (proposalId: string) => `/proposals/${proposalId}`,
            milestones: (proposalId: string) => `/proposals/${proposalId}/milestones`,
        },
        users: {
            index: "/users",
            create: "/users/create",
            edit: (userId: string) => `/users/${userId}`,
        },
        talents: {
            index: `/${talentPlural}`,
            skill: (skillId: string) => `/${talentPlural}/skill/${skillId}`,
            badge: (badgeId: string) => `/${talentPlural}/badge/${badgeId}`,
            edit: (talentId: string) => `/${talentPlural}/${talentId}`,
        },
        skills: {
            index: "/skills",
            create: "/skills/create",
            edit: (skillId: string) => `/skills/edit/${skillId}`,
            categories: {
                index: "/skills/categories",
                edit: (skillCategoryId: string) => `/skills/categories/${skillCategoryId}`,
                create: "/skills/categories/create"
            },
        },
        reports: {
            index: "/reports",
            view: (reportId: string) => `/reports/${reportId}`
        },
        milestones: {
            view: (proposalMilestoneId: string) => `/milestones/${proposalMilestoneId}`,
            edit: (proposalMilestoneId: string) => `/milestones/${proposalMilestoneId}/edit`
        },
        agencies: {
            index: "/agencies",
            edit: (agencyId: string) => `/agencies/${agencyId}`,
            create: "/agencies/create"
        },
        badges: {
            index: "/badges",
            edit: (badgeId: string) => `/badges/${badgeId}`,
            create: "/badges/create",
        },
        roles: {
            index: "/roles",
            view: (roleId: string) => `/roles/${roleId}`,
            edit: (roleId: string) => `/roles/${roleId}`,
            create: "/roles/create",
            categories: {
                index: "/roles/categories",
                edit: (roleCategoryId: string) => `/roles/categories/${roleCategoryId}`,
                create: "/roles/categories/create"
            },
            assignments: {
                index: "/roles/assignments",
                view: (roleAssignmentId: string) => `/roles/assignments/${roleAssignmentId}`
            }
        },
        locations: {
            index: "/locations",
            edit: (locationId: string) => `/locations/${locationId}`,
            create: "/locations/create",
        },
        applications: {
            index: "/index",
            view: (applicationId: string) => `/applications/${applicationId}`,
        },
        settings: "/settings",
        fourOFour,
    });
};

export const useAppPaths = () => {
    const { gigTerminology, gigTerminologyPlural, giggedClientTerminologyPlural, talentTerminologyPlural } = useOrganizationConfig();

    return appPaths(gigTerminology, gigTerminologyPlural, giggedClientTerminologyPlural, talentTerminologyPlural);
};

const AppRoutes: React.FC = () => {
    const routeAppPaths = useAppPaths();
    const { organizationConfig } = useOrganizationConfig();

    return (
        <Routes>
            <Route path={routeAppPaths.index} element={<DashboardPage />} />
            <Route path={routeAppPaths.clients.index} element={<ClientsListPage />} />
            <Route path={routeAppPaths.clients.create} element={<ClientCreatePage />} />
            <Route path={routeAppPaths.clients.edit(":clientId")} element={<ClientEditPage />} />
            <Route path={routeAppPaths.gigs.index} element={<GigsListPage />} />
            <Route path={routeAppPaths.gigs.view(":gigId")} element={<GigPage />} />
            <Route path={routeAppPaths.gigs.edit(":gigId")} element={<GigPage />} />
            <Route path={routeAppPaths.proposals.index} element={<ProposalsListPage />} />
            <Route path={routeAppPaths.applications.index} element={<ApplicationsListPage />} />
            <Route path={routeAppPaths.applications.view(":applicationId")} element={<ApplicationPage />} />
            <Route path={routeAppPaths.proposals.milestones(":proposalId")} element={<MilestonesListPage />} />
            <Route path={routeAppPaths.milestones.view(":proposalMilestoneId")} element={<ProposalMilestonePage />} />
            <Route path={routeAppPaths.milestones.edit(":proposalMilestoneId")} element={<ProposalMilestoneEditForm />} />
            <Route path={routeAppPaths.gigs.proposals(":gigId")} element={<ProposalsListPage />} />
            <Route path={routeAppPaths.proposals.view(":proposalId")} element={<ProposalPage />} />
            <Route path={routeAppPaths.users.index} element={<UsersListPage />} />
            <Route path={routeAppPaths.users.create} element={<UserCreatePage />} />
            <Route path={routeAppPaths.users.edit(":userId")} element={<UserEditPage />} />
            <Route path={routeAppPaths.talents.index} element={<TalentsListPage />} />
            <Route path={routeAppPaths.talents.skill(":skillIds")} element={<TalentsListPage />} />
            {organizationConfig?.isBadgesEnabled && <Route path={routeAppPaths.talents.badge(":badgeIds")} element={<TalentsListPage />} />}
            {organizationConfig?.isTalentAgencyEnabled && (
                <>
                    <Route path={routeAppPaths.talents.edit(":talentId")} element={<TalentEditPage />} />
                </>
            )}
            <Route path={routeAppPaths.skills.index} element={<SkillsListPage />} />
            <Route path={routeAppPaths.skills.create} element={<SkillCreatePage />} />
            <Route path={routeAppPaths.skills.edit(":skillId")} element={<SkillEditPage />} />
            <Route path={routeAppPaths.skills.categories.index} element={<SkillCategoriesListPage />} />
            <Route path={routeAppPaths.skills.categories.edit(":skillCategoryId")} element={<SkillCategoryEditPage />} />
            <Route path={routeAppPaths.skills.categories.create} element={<SkillCategoryCreatePage />} />
            <Route path={routeAppPaths.settings} element={<OrganizationSettingsPage />} />

            {organizationConfig?.isReportsEnabled && (
                <>
                    <Route path={routeAppPaths.reports.index} element={<ReportsListPage />} />
                    <Route path={routeAppPaths.reports.view(":reportId")} element={<ReportPage />} />
                </>
            )}

            {organizationConfig?.isTalentAgencyEnabled && (
                <>
                    <Route path={routeAppPaths.agencies.index} element={<AgenciesListPage />} />
                    <Route path={routeAppPaths.agencies.edit(":agencyId")} element={<AgencyEditPage />} />
                    <Route path={routeAppPaths.agencies.create} element={<AgencyCreatePage />} />
                </>
            )}

            {organizationConfig?.isBadgesEnabled && (
                <>
                    <Route path={routeAppPaths.badges.index} element={<BadgesListPage />} />
                    <Route path={routeAppPaths.badges.edit(":badgeId")} element={<BadgeEditPage />} />
                    <Route path={routeAppPaths.badges.create} element={<BadgeCreatePage />} />
                </>
            )}

            <Route path={routeAppPaths.roles.categories.index} element={<RoleCategoriesListPage />} />
            <Route path={routeAppPaths.roles.categories.edit(":roleCategoryId")} element={<RoleCategoryEditPage />} />
            <Route path={routeAppPaths.roles.categories.create} element={<RoleCategoryCreatePage />} />

            <Route path={routeAppPaths.roles.index} element={<RolesListPage />} />
            <Route path={routeAppPaths.roles.view(":roleId")} element={<RolePage />} />
            <Route path={routeAppPaths.roles.edit(":roleId")} element={<RolePage />} />
            <Route path={routeAppPaths.roles.create} element={<RoleCreatePage />} />
            <Route path={routeAppPaths.roles.assignments.index} element={<RoleAssignmentsListPage/>} />
            <Route path={routeAppPaths.roles.assignments.view(":roleAssignmentId")} element={<RoleAssignmentPage />} />


            <Route path={routeAppPaths.locations.index} element={<LocationsListPage />} />
            <Route path={routeAppPaths.locations.edit(":locationId")} element={<LocationEditPage />} />
            <Route path={routeAppPaths.locations.create} element={<LocationCreatePage />} />

            <Route path={routeAppPaths.fourOFour} element={<FourOFourPage />} />
            <Route path="*" element={<Navigate to={routeAppPaths.index} replace />} />
        </Routes>
    );
};

export const useGigId = (): string => {
    const { gigId } = useParams();

    if (!gigId) throw new Error("No gig id param available.");

    return gigId;
};

export const useTalentId = (): string => {
    const { talentId } = useParams();

    if (!talentId) throw new Error("No talent id param available.");

    return talentId;
};

export const useReportId = (): string => {
    const { reportId } = useParams();

    if (!reportId) throw new Error("No report id param available.");

    return reportId;
};

export const useProposalId = (): string => {
    const { proposalId } = useParams();

    if (!proposalId) throw new Error("No proposal id param available.");

    return proposalId;
};

export const useProposalMilestoneId = (): string => {
    const { proposalMilestoneId } = useParams<{ proposalMilestoneId: string }>();

    if (!proposalMilestoneId) throw new Error("No proposal milesetone id param available.");

    return proposalMilestoneId;
};

export const useAgencyId = (): string => {
    const { agencyId } = useParams<{ agencyId: string }>();

    if (!agencyId) throw new Error("No agency id param available.");

    return agencyId;
};

export const useBadgeId = (): string => {
    const { badgeId } = useParams<{ badgeId: string }>();

    if (!badgeId) throw new Error("No badge id param available.");

    return badgeId;
};

export const useSkillCategoryId = (): string => {
    const { skillCategoryId } = useParams<{ skillCategoryId: string }>();

    if (!skillCategoryId) throw new Error("No skill category id param available.");

    return skillCategoryId;
};

export const useSkillId = (): string => {
    const { skillId } = useParams<{ skillId: string }>();

    if (!skillId) throw new Error("No skill id param available.");

    return skillId;
};

export const useRoleCategoryId = (): string => {
    const { roleCategoryId } = useParams<{ roleCategoryId: string }>();

    if (!roleCategoryId) throw new Error("No role category id param available.");

    return roleCategoryId;
};

export const useRoleId = (): string => {
    const { roleId } = useParams<{ roleId: string }>();

    if (!roleId) throw new Error("No role id param available.");

    return roleId;
};

export const useLocationId = (): string => {
    const { locationId } = useParams<{ locationId: string }>();

    if (!locationId) throw new Error("No location id param available.");

    return locationId;
};

export const useApplicationId = (): string => {
    const { applicationId } = useParams<{ applicationId: string }>();

    if (!applicationId) throw new Error("No application id param available.");

    return applicationId;
};

export const useRoleAssignmentId = (): string => {
    const { roleAssignmentId } = useParams<{ roleAssignmentId: string }>();

    if (!roleAssignmentId) throw new Error("No role assignment id param available.");

    return roleAssignmentId;
};

export default AppRoutes;