

import { LoadingButton } from "@mui/lab";
import { Box, DialogActions, Button } from "@mui/material";
import { FormProvider, Controller, UseFormReturn } from "react-hook-form";

import DialogBox from "../DialogBox/DialogBox";
import FormErrors from "../FormErrors";
import FormInputLabel from "../FormInputLabel";
import FormTextInput from "../FormTextInput";
import TalentSearchDropdown from "../TalentSearchDropdown";
import { AssignRoleFormValues } from "./AssignRoleFormValues";
import { useOrganizationConfig } from "../../api/organization";

export type AssignRoleFormDialogProps = {
    isOpen: boolean
    onClose: () => void
    handleAssign: (values: AssignRoleFormValues) => Promise<void>
    loading: boolean
    disabledTalentIds?: string[]
    methods: UseFormReturn<AssignRoleFormValues>
    error?: string[]
}

const AssignRoleFormDialog = ({
    isOpen,
    onClose,
    handleAssign,
    loading,
    disabledTalentIds,
    methods,
    error
}: AssignRoleFormDialogProps) => {
    const { talentTerminology } = useOrganizationConfig();

    return (
        <DialogBox
            isOpen={isOpen}
            onClose={onClose}
            title={`Assign ${talentTerminology.toLowerCase()}`}
            fullWidth
        >
            <FormProvider {...methods} >
                <form
                    onSubmit={methods.handleSubmit(handleAssign)}
                    noValidate
                >
                    <div className="space-y-4">

                        <Box className="space-y-2 flex flex-col">
                            <FormInputLabel required>Select {talentTerminology.toLowerCase()} to assign</FormInputLabel>
                            <Controller
                                name="talentId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <TalentSearchDropdown
                                        error={methods.formState.errors.talentId}
                                        onChange={onChange}
                                        value={value}
                                        isSubmitting={loading}
                                    />
                                )}
                            />
                        </Box>
                        <Box className="space-y-2">
                            <FormInputLabel required>Message</FormInputLabel>
                            <FormTextInput
                                required
                                multiline
                                rows={3}
                                name="message"
                            />
                        </Box>
                        <Box sx={{ marginTop: "1.5rem" }}>
                            <DialogActions>
                                <Box className="flex flex-col md:flex-row-reverse md:justify-between md:items-center md:space-x-2 w-full space-y-2 md:space-y-0">
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        loading={loading}
                                    >
                                        Assign now
                                    </LoadingButton>
                                    <Button type="button" variant="text" color="secondary" disabled={loading} onClick={onClose}>Cancel</Button>
                                </Box>
                            </DialogActions>
                        </Box>
                    </div>
                </form>
            </FormProvider>
            <FormErrors messages={error} />
        </DialogBox>
    );
};

export default AssignRoleFormDialog;