import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type BadgeCreateFormValues = {
    name: string,
    description: string,
    imageFileName: string,
};

export const useBadgeForm = (initialValues?: Partial<BadgeCreateFormValues>) => {
    return useForm<BadgeCreateFormValues>({
        resolver: yupResolver(badgeValidationSchema),
        mode: "onSubmit",
        defaultValues: {
            ...initialValues,
        },
    });
};

const badgeValidationSchema = yup
    .object({
        name: yup.string().required("Name is required."),
        description: yup.string().required("Description is required."),
        imageFileName: yup.string()
            .required("Image file name is required."),
    })
    .required();

