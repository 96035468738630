import { useEffect } from "react";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Link } from "react-router-dom";

import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { useAppPaths } from "../../Routes";
import formatDateOnlyString from "../../utils/formatDateString";
import { useRoles } from "../../api/roles";
import { useOrganizationConfig } from "../../api/organization";
import StatusCell from "../GigsTable/GigStatusCell";
import { roleAssignmentTypeOptions, roleStatusOptions, roleVisibilityOptions } from "../../api/models/role";

export type RolesTableProps = {
    searchTerm?: string
}

const RolesTable = ({ searchTerm }: RolesTableProps) => {
    const roles = useRoles();
    const appPaths = useAppPaths();
    const { giggedClientTerminology, talentTerminology } = useOrganizationConfig();

    const setSearchTerm = roles.setSearchTerm;

    const handleOrderByChange = (property: string) => {
        const isAsc = roles.orderBy === property && roles.sortOrder === "asc";
        roles.setSortOrder(isAsc ? "desc" : "asc");
        roles.setOrderBy(property);
    };

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    if (roles.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "Title",
                        label: "Role title",
                        isSortable: true,
                    },
                    {
                        key: "RoleCategoryName",
                        label: "Role category",
                        isSortable: true,
                    },
                    {
                        key: "GiggedClientName",
                        label: giggedClientTerminology,
                        isSortable: true,
                    },
                    {
                        label: `${talentTerminology} count`,
                        isSortable: false,
                    },
                    {
                        key: "RoleStatusId",
                        label: "Status",
                        isSortable: true,
                    },
                    {
                        key: "RoleVisibilityTypeId",
                        label: "Visibility",
                        isSortable: true,
                    },
                    {
                        key: "RoleAssignmentTypeId",
                        label: "Assignment type",
                        isSortable: true,
                    },
                    {
                        key: "CreatedAt",
                        label: "Date created",
                        isSortable: true,
                    },
                    {
                        label: "",
                        isSortable: false
                    }
                ]}
                data={roles.roles}
                renderRow={role => (
                    <>

                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{role.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{role.title}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{role.roleCategoryName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{role.giggedClientName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{role?.hiredTalents?.length}</TableCell>
                        <StatusCell statusId={role.roleStatusId} options={roleStatusOptions} />
                        <StatusCell statusId={role.roleVisibilityTypeId} options={roleVisibilityOptions} />
                        <StatusCell statusId={role.roleAssignmentTypeId} options={roleAssignmentTypeOptions} />
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(role.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.roles.edit(role.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={roles.setPageIndex}
                setPageSize={roles.setPageSize}
                pageSize={roles.pageSize}
                pageIndex={roles.pageIndex}
                totalCount={roles.totalCount}
                orderBy={roles.orderBy}
                sortOrder={roles.sortOrder}
                isLoading={roles.isLoading}
            />
        </TableCard>
    );
};

export default RolesTable;
