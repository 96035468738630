import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type RoleCategoryEditFormValues = {
    roleCategoryName: string
};

export const useRoleCategoryEditForm = (initialValues: RoleCategoryEditFormValues) => {
    const schema = roleCategoryValidationSchema();

    return useForm<RoleCategoryEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: initialValues
    });
};

const roleCategoryValidationSchema = () => {
    return yup
        .object({
            roleCategoryName: yup.string().required("Role category name is required."),
        })
        .required();
};