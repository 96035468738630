import clsx from "clsx";
import { FieldError } from "react-hook-form";

export type FormInputMessageProps = {
    error: FieldError
}

const FormInputMessage = ({
    error,
}: FormInputMessageProps) => {
    return (
        <span className={clsx("text-[0.75rem] ml-4 !mt-[0.5rem] block text-error")}>{error.message}</span>
    );

};

export default FormInputMessage;