import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Divider, Typography } from "@mui/material";

export type InvitedEmailDisplayProps = {
    invitedEmails: string[]
}

const InvitedEmailDisplay = ({
    invitedEmails
}: InvitedEmailDisplayProps) => {
    return (
        <>
            {invitedEmails.map((email) => (
                <Box key={email} sx={{ display: "flex", flexDirection: "column" }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "15px"
                        }}
                    >
                        <AccountCircleIcon fontSize="large" sx={{ marginRight: 1, color: "gray" }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography component="span" sx={{ color: "gray" }}>
                                Invited user
                            </Typography>
                            <Typography component="span" sx={{ color: "gray" }}>
                                {email}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                </Box>
            ))}
        </>
    );
};

export default InvitedEmailDisplay;