import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { Box, Button, Divider, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddIcon from "@mui/icons-material/Add";

import { ClientDetails } from "../../api/models/client";
import { useClient } from "../../api/client";
import ApiError from "../../api/common/apiError";
import MemberEmailDisplay from "../MemberEmailDisplay";
import InvitedEmailDisplay from "./InvitedEmailDisplay";
import ConfirmDialogBox from "../ConfirmDialogBox";
import FormTextInput from "../FormTextInput";
import ClientInviteUsersFormDialog from "./ClientInviteUsersFormDialog";
import { ClientInviteUserFormValues } from "./ClientInviteUsersFormValues";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { successToast } from "../../toast";
import { ClientEditFormValues, useEditClientForm } from "./ClientEditFormValues";
import { useOrganizationConfig } from "../../api/organization";
import FormInputLabel from "../FormInputLabel";
import { ClientAddTalentFormValues } from "./ClientAddTalentFormValues";
import ClientAddTalentFormDialog from "./ClientAddTalentFormDialog";
import TalentDisplay from "./TalentDisplay";

export type ClientEditFormProps = {
    onSubmit: (values: ClientEditFormValues) => Promise<GigApiFetcherResponse<void>>
    initialValues: ClientDetails
    isSubmitting: boolean
}

const ClientEditForm = ({
    initialValues,
    onSubmit,
    isSubmitting,
}: ClientEditFormProps) => {
    const [clientInviteUsersDialogIsOpen, setClientInviteUsersDialogIsOpen] = useState(false);
    const [addTalentDialogIsOpen, setAddTalentDialogIsOpen] = useState(false);
    const appPaths = useAppPaths();
    const isLargeScreen = useIsLargeScreen();
    const navigate = useNavigate();
    const theme = useTheme();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const {
        deleteUserFromClient,
        inviteClientUser,
        deleteClient,
        addTalent,
        isAddingTalent,
        isInvitingClientUser,
        isDeletingUserFromClient,
        isDeletingClient,
        client
    } = useClient(initialValues.id);
    const methods = useEditClientForm({
        ...initialValues,
        locationId: initialValues.location?.id || ""
    });    const { formState: { isDirty } } = methods;
    const { giggedClientTerminology, gigTerminologyPlural, talentTerminology, talentTerminologyPlural } = useOrganizationConfig();

    const handleFormSubmit = async (values: ClientEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.clients.index);
        }
    });

    const [openDeleteDialog, deleteDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleDelete();
        }
    });

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.clients.index);
        }
    };

    const handleDelete = async () => {
        setSubmissionError(undefined);

        const response = await deleteClient(initialValues.id);

        if (!response.success) setSubmissionError(response.error);
        else navigate(appPaths.clients.index);
    };

    const handleInviteClientUser = async (values: ClientInviteUserFormValues) => {
        const response = await inviteClientUser(values);

        if (response.success) {
            successToast("Email invitations sent.");
            setClientInviteUsersDialogIsOpen(false);
        }

        return response;
    };

    const handleAddTalent = async (values: ClientAddTalentFormValues) => {
        const response = await addTalent(values);

        if (response.success) {
            successToast(`${talentTerminology} successfully added.`);
            setAddTalentDialogIsOpen(false);
        }

        return response;
    };


    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title={
                            <FormTextInput
                                name="name"
                                disabled={isSubmitting}
                                variant="standard"
                                sx={{ backgroundColor: theme.palette.background.paper }}
                            />
                        }>

                        <Box sx={{
                            minHeight: "25rem",
                            display: "flex",
                            flexDirection: "column",
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            }
                        }}>
                            <Box className="space-y-2">
                                <FormInputLabel>Email</FormInputLabel>
                                <FormTextInput sx={{ pointerEvents: "none" }} name="" disabled placeholder={initialValues?.memberUsers[0] ? initialValues?.memberUsers[0].email : "Email not specified"} />
                            </Box>

                            <Box className="space-y-2">
                                <FormInputLabel>Overview</FormInputLabel>
                                <FormTextInput multiline name="aboutMe" />
                            </Box>
                            <Box className="space-y-2">
                                <FormInputLabel>{`${giggedClientTerminology} cost code`}</FormInputLabel>
                                <FormTextInput name="companyCostCode" />
                            </Box>

                            <>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <FormInputLabel>{giggedClientTerminology} Users</FormInputLabel>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        onClick={() => setClientInviteUsersDialogIsOpen(true)}
                                        startIcon={<AddIcon />}
                                    >
                                        Invite {isLargeScreen && "new user"}
                                    </Button>
                                </Box>
                                {initialValues.memberUsers.length > 0 ? (
                                    <>
                                        {initialValues.memberUsers.map(memberUser => (
                                            <MemberEmailDisplay
                                                key={memberUser.userId}
                                                numberOfUsers={initialValues.memberUsers.length}
                                                user={memberUser}
                                                clientName={initialValues.name}
                                                deleteUser={deleteUserFromClient}
                                                isDeleting={isDeletingUserFromClient}
                                            />
                                        ))}
                                        <InvitedEmailDisplay invitedEmails={initialValues?.invitedUserEmails} />
                                    </>
                                ) : <span>None yet</span>}
                            </>


                            <Divider />

                            <>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <FormInputLabel>{giggedClientTerminology} {talentTerminologyPlural}</FormInputLabel>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        onClick={() => setAddTalentDialogIsOpen(true)}
                                        startIcon={<AddIcon />}
                                    >
                                        Add {isLargeScreen && `${talentTerminology.toLowerCase()}`}
                                    </Button>

                                </Box>
                                {initialValues.talents.length > 0 ? (
                                    initialValues.talents.map(talent => (
                                        <TalentDisplay key={talent.id} talentId={talent.id} talentName={talent.name} />
                                    ))
                                ) : <span>None yet</span>}
                            </>

                            <FormErrors messages={submissionError?.userMessages} />
                            <Box sx={
                                isLargeScreen ?
                                    { display: "flex", justifyContent: "flex-end", marginTop: 4, gap: 2 } :
                                    { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: 4 }
                            }>
                                <LoadingButton
                                    color="secondary"
                                    startIcon={<DeleteForeverOutlinedIcon sx={isSubmitting || isDeletingClient ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting || isDeletingClient}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                    onClick={openDeleteDialog}
                                >
                                    Delete
                                </LoadingButton>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting || isDeletingClient ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting || isDeletingClient}
                                    disabled={!isDirty || isDeletingClient}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            {clientInviteUsersDialogIsOpen && (
                <ClientInviteUsersFormDialog
                    isOpen={true}
                    onClose={() => setClientInviteUsersDialogIsOpen(false)}
                    handleInviteClientUser={handleInviteClientUser}
                    isInviting={isInvitingClientUser}
                />
            )}
            {addTalentDialogIsOpen && (
                <ClientAddTalentFormDialog
                    isOpen={true}
                    onClose={() => setAddTalentDialogIsOpen(false)}
                    handleAddTalent={handleAddTalent}
                    isAdding={isAddingTalent}
                    disabledTalentIds={initialValues.talents.map(_ => _.id)}
                />
            )}
            <ConfirmDialogBox
                {...deleteDialogState}
                message={`Are you sure you want to delete this ${giggedClientTerminology.toLowerCase()}? This action cannot be undone.`}
                warningMessage={client?.hasAnyOngoingGigs ? `This ${giggedClientTerminology.toLowerCase()} has one or more ongoing ${gigTerminologyPlural.toLowerCase()}.` : undefined}
                confirmButtonText="Delete"
            />
        </>
    );
};

export default ClientEditForm;