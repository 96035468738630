import { FormProvider } from "react-hook-form";
import { Box, Button, DialogActions, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import FormChipInput from "../FormChipInput";
import { ClientInviteUserFormValues, useClientInviteUsersForm } from "./ClientInviteUsersFormValues";
import DialogBox from "../DialogBox/DialogBox";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { GigApiFetcherResponse } from "../../api/common/fetching";

export type InviteUsersFormProps = {
    isOpen: boolean
    onClose: () => void
    handleInviteClientUser: (values: ClientInviteUserFormValues) => Promise<GigApiFetcherResponse<void>>
    isInviting: boolean
}

const ClientInviteUsersFormDialog = ({
    isOpen,
    onClose,
    handleInviteClientUser,
    isInviting
}: InviteUsersFormProps) => {
    const methods = useClientInviteUsersForm();
    const { handleSubmit, formState: { isDirty, isValidating } } = methods;
    const isLargeScreen = useIsLargeScreen();

    return (

        <DialogBox isOpen={isOpen}>
            <Box sx={isLargeScreen ? { width: "28rem" } : { width: "15rem" }}>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(handleInviteClientUser)}
                        noValidate
                    >
                        <Typography sx={{ fontWeight: 700, color: "black", marginBottom: "1.5rem" }}>
                            Invite new user
                        </Typography>
                        <FormChipInput
                            name="emails"
                            placeholder="Enter email address"
                            label="Add users"
                        />
                        <Box sx={{ marginTop: "1.5rem" }}>
                            <DialogActions>
                                <Box className="flex flex-col md:flex-row-reverse md:justify-between md:items-center md:space-x-2 w-full space-y-2 md:space-y-0">
                                    <LoadingButton 
                                        type="submit" 
                                        variant="contained" 
                                        color="primary" 
                                        loading={isInviting || isValidating} 
                                        disabled={!isDirty}
                                    >
                                        Send invitations
                                    </LoadingButton>
                                    <Button type="button" variant="text" color="secondary" disabled={isInviting || isValidating} onClick={onClose}>Cancel</Button>
                                </Box>
                            </DialogActions>
                        </Box>
                    </form>
                </FormProvider>
            </Box>
        </DialogBox>
    );
};

export default ClientInviteUsersFormDialog;