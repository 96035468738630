import { InsertDriveFile } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";

import FileDropZone from "../FileDropZone";
import FormErrors from "../FormErrors";
import FormInputLabel from "../FormInputLabel";

export type FormFileUploadInputProps = {
    label?: string
    helpText?: string
    inputId: string
    fileSizeDescription?: string,
    fileName?: string
    error?: string
    isDirty: boolean
    onLoadFile: (file: File) => void
    required?: boolean
    disabled?: boolean
    tooltip?: string
}

const FormFileUploadInput = ({
    label,
    helpText,
    inputId,
    fileSizeDescription,
    fileName,
    error,
    isDirty,
    onLoadFile,
    required,
    disabled,
    tooltip,
}: FormFileUploadInputProps) => {
    return (
        <div>
            {label && (
                <FormInputLabel required={required} tooltipText={tooltip}>{label}</FormInputLabel>
            )}
            <div className="space-y-3">
                {helpText &&
                    <Typography> {helpText} </Typography>
                }
                <FileDropZone
                    inputId={inputId}
                    onFileDrop={onLoadFile}
                    disabled={disabled}
                    className="mb-4"
                    helpText="Drag and drop your CSV file here"
                />
                {error &&
                    <div className="mb-6">
                        <FormErrors messages={[error]} />
                    </div>
                }
                {isDirty && !error ? (
                    <div className="flex justify-center sm:block">
                        <div className="w-40 mb-4">
                            <>
                                <p className="font-bold break-words">{fileName}</p>
                                <p>File size: {fileSizeDescription}</p>
                            </>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default FormFileUploadInput;
