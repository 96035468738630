import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton, TableCell } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import formatDateOnlyString from "../../utils/formatDateString";
import Loader from "../Loader";
import Table from "../Table";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import TableChipCell from "../Table/TableChipCell";
import { UseApplicationsReturn } from "../../api/applications";
import { applicationStatuses } from "../../api/models/application";
import { capitalizeStrings } from "../../utils/capitalizeString";
import { useAppPaths } from "../../Routes";

export type ApplicationsTableProps = {
    searchTerm?: string,
    applications: UseApplicationsReturn
};

const ProposalsTable = ({
    searchTerm,
    applications,
}: ApplicationsTableProps) => {
    const { talentTerminology, giggedClientTerminology } = useOrganizationConfig();
    const appPaths = useAppPaths();

    useEffect(() => {
        applications.setSearchTerm(searchTerm);
    }, [applications.setSearchTerm, searchTerm]);

    const handleOrderByChange = (property: string) => {
        const isAsc = applications.orderBy === property && applications.sortOrder === "asc";
        applications.setSortOrder(isAsc ? "desc" : "asc");
        applications.setOrderBy(property);
    };

    const headers = [
        {
            label: "ID",
            isSortable: false,
        },
        {
            key: "RoleTitle",
            label: "Role title",
            isSortable: true
        },
        {
            key: "TalentName",
            label: `${talentTerminology} name`,
            isSortable: true,
        },
        {
            key: "GiggedClientName",
            label: `Role ${giggedClientTerminology.toLowerCase()}`,
            isSortable: true,
        },
        {
            key: "ApplicationStatusId",
            label: "Status",
            isSortable: true,
        },
        {
            key: "CreatedAt",
            label: "Date created",
            isSortable: true,
        },
        {
            label: "",
            isSortable: false
        }
    ];

    if (applications.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={headers}
                data={applications.applications}
                renderRow={application => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{application.id}</TableCell>
                        <TableCell sx={{ maxWidth: "250px" }}>{application.roleTitle}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{application.talentFirstName} {application.talentLastName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{application.giggedClientName}</TableCell>
                        <TableChipCell labels={[capitalizeStrings(Object.keys(applicationStatuses)[application.applicationStatusId])]} />
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(application.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.applications.view(application.id)}>
                                <IconButton aria-label="edit" >
                                    <VisibilityIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={applications.setPageIndex}
                setPageSize={applications.setPageSize}
                orderBy={applications.orderBy}
                sortOrder={applications.sortOrder}
                pageSize={applications.pageSize}
                pageIndex={applications.pageIndex}
                totalCount={applications.totalCount}
                isLoading={applications.isLoading}
            />
        </TableCard>
    );
};

export default ProposalsTable;