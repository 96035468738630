import { useEffect, useState } from "react";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import { Box, useTheme } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";

import FormTextInput from "../FormTextInput";
import ApiError from "../../api/common/apiError";
import FormErrors from "../FormErrors";
import ConfirmDialog from "../ConfirmDialogBox";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../../Routes";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { RoleCreateFormValues, useRoleCreateForm } from "./RoleCreateFormValues";
import FormInputLabel from "../FormInputLabel";
import { RoleCategoryInput } from "../RoleCategoriesSearchDropdown";
import { RoleClientInput } from "../RoleDepartmentDropdown";
import SkillsInput from "../SkillsInput";
import { useOrganizationConfig } from "../../api/organization";
import { roleAssignmentTypeOptions, roleAssignmentTypes, roleStatuses, roleVisibilityOptions, roleVisibilityTypes } from "../../api/models/role";
import FormRadioInput from "../FormRadioInput";

type RoleCreateFormProps = {
    onSubmit: (values: RoleCreateFormValues) => Promise<GigApiFetcherResponse<string>>
    isSubmitting: boolean
}

const RoleCreateForm = ({
    onSubmit,
    isSubmitting
}: RoleCreateFormProps) => {
    const { giggedClientTerminology } = useOrganizationConfig();
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.roles.index);
        }
    });

    const methods = useRoleCreateForm();
    const { formState: { isDirty, errors, submitCount }, watch, control, handleSubmit } = methods;

    const { skills, roleAssignmentTypeId } = watch();

    useEffect(() => {
        if (roleAssignmentTypeId === roleAssignmentTypes.assignment) {
            methods.setValue("roleVisibilityTypeId", roleVisibilityTypes.organisation);
        }
    }, [roleAssignmentTypeId]);

    const { append: appendSkill, remove: removeSkill } = useFieldArray<RoleCreateFormValues, "skills">({
        control: control,
        name: "skills",
        keyName: "id",
    });

    const handleRoleSubmit = async (values: RoleCreateFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit({
            ...values,
            roleStatusId: roleStatuses.open
        });

        if (!response.success) setSubmissionError(response.error);

        return response;
    };

    const handleSaveAsDraft = async (values: RoleCreateFormValues) => {
        setSubmissionError(undefined);

        const isValid = await methods.trigger();

        if (isValid) {
            const response = await onSubmit({
                ...values,
                roleStatusId: roleStatuses.draft
            });

            if (!response.success) setSubmissionError(response.error);

            return response;
        }
    };

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.roles.index);
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(handleRoleSubmit)}
                    noValidate
                >
                    <FormCard onClose={handleCloseButton} title="Create Role">
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: theme.spacing(2),

                        }}>
                            <Box className="space-y-2">
                                <FormInputLabel required>Role title</FormInputLabel>
                                <FormTextInput
                                    required
                                    name="title"
                                    disabled={isSubmitting}
                                />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: theme.spacing(2),

                        }}>
                            <Box className="space-y-2">
                                <FormInputLabel required>Role description</FormInputLabel>
                                <FormTextInput
                                    required
                                    multiline
                                    name="description"
                                    disabled={isSubmitting}
                                />
                            </Box>
                        </Box>
                        <Box className="space-y-4">
                            <Box className="space-y-2 flex flex-col">
                                <FormInputLabel required>Role category</FormInputLabel>
                                <Controller
                                    name="roleCategoryId"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <RoleCategoryInput
                                            error={errors.roleCategoryId}
                                            onChange={onChange}
                                            value={value}
                                            isSubmitting={isSubmitting}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className="space-y-2 flex flex-col">
                                <FormInputLabel required>{giggedClientTerminology}</FormInputLabel>
                                <Controller
                                    name="giggedClientId"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <RoleClientInput
                                            error={errors.giggedClientId}
                                            onChange={onChange}
                                            value={value}
                                            isSubmitting={isSubmitting}
                                        />
                                    )}
                                />
                            </Box>
                            <SkillsInput
                                addSkill={appendSkill}
                                removeSkill={removeSkill}
                                skills={skills || []}
                                error={errors.skills}
                                showError={submitCount > 0}
                                maxSkills={8}
                                required
                                title="Skills required"
                            />
                            <FormRadioInput
                                name="roleAssignmentTypeId"
                                label={"Role assignment type"}
                                options={roleAssignmentTypeOptions}
                                error={methods.formState.errors.roleAssignmentTypeId}
                                disabled={false}
                            />
                            {roleAssignmentTypeId === roleAssignmentTypes.applications ?
                                <FormRadioInput
                                    name="roleVisibilityTypeId"
                                    label={"Role visibility"}
                                    options={roleVisibilityOptions}
                                    error={methods.formState.errors.roleVisibilityTypeId}
                                    disabled={false}
                                /> 
                                : null
                            }
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "flex-end", marginTop: "2rem", gap: 2 } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "2rem" }
                        }>
                            <LoadingButton
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                type="submit"
                                color="secondary"
                                onClick={() => handleSaveAsDraft(methods.getValues())}
                            >
                                Save as draft
                            </LoadingButton>

                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Create role
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider >
            <ConfirmDialog
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default RoleCreateForm;