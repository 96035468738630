import { useEffect } from "react";
import { IconButton, TableCell } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import config from "../../config.json";
import Table from "../Table";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import { UseProposalMilestonesReturn } from "../../api/proposalMilestones";
import formatDateOnlyString from "../../utils/formatDateString";
import formatDateTimeString from "../../utils/formatDateTimeString";
import { useAppPaths } from "../../Routes";
import { Link } from "react-router-dom";
import Loader from "../Loader";

export type ProposalMilestonesTableProps = {
    filteredProposalId?: string
    milestones: UseProposalMilestonesReturn
};

const ProposalMilestonesTable = ({
    filteredProposalId,
    milestones
}: ProposalMilestonesTableProps) => {
    const { gigTerminology, talentTerminology } = useOrganizationConfig();
    const appPaths = useAppPaths();

    useEffect(() => {
        milestones.setProposalId(filteredProposalId);
    }, [milestones.setProposalId, filteredProposalId]);
    
    const headers = [
        {
            label: "ID",
            isSortable: false
        },
        {
            label: `${gigTerminology} title`,
            isSortable: false,
        },
        {
            label: `${talentTerminology} name`,
            isSortable: false,
        },
        {
            label: "Description",
            isSortable: false,
        },
        {
            label: "Amount",
            isSortable: false,
        },
        {
            label: "Status",
            isSortable: false
        },
        {
            label: "Date created",
            isSortable: false,
        },
        {
            label: "Last updated",
            isSortable: false,
        },
        {
            label: "Date completed",
            isSortable: false,
        },
        {
            label: "",
            isSortable: false,
            center: true,
        },
    ];

    if (milestones.isLoading) return <Loader />;

    return (
        <TableCard>
            <Table
                headers={headers}
                data={milestones.proposalMilestones}
                renderRow={milestone => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{milestone.id}</TableCell>
                        <TableCell>{milestone.gigTitle}</TableCell>
                        <TableCell>{milestone.talentName}</TableCell>
                        <TableCell>{milestone.description}</TableCell>
                        <TableCell>{milestone.dateCompleted ? "Completed" : "Ongoing"}</TableCell>
                        <TableCell>{formatDateTimeString(milestone.createdAt)}</TableCell>
                        <TableCell>{formatDateTimeString(milestone.lastUpdatedAt) || "-"}</TableCell>
                        <TableCell>{formatDateOnlyString(milestone.dateCompleted) || "-"}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.milestones.view(milestone.id)}>
                                <IconButton aria-label="view" >
                                    <VisibilityIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                setPageIndex={milestones.setPageIndex}
                setPageSize={milestones.setPageSize}
                pageSize={milestones.pageSize}
                pageIndex={milestones.pageIndex}
                totalCount={milestones.totalCount}
                isLoading={milestones.isLoading}
            />
        </TableCard>
    );
};

export default ProposalMilestonesTable;