import { useNavigate } from "react-router-dom";

import FormContainer from "../components/FormContainer";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useLocations } from "../api/locations";
import { LocationCreateForm } from "../components/LocationCreateForm";
import { LocationFormValues } from "../components/LocationEditForm/LocationFormValues";

const LocationCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createLocation, isCreatingLocation } = useLocations();

    const onSubmit = async (values: LocationFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createLocation(values);

        if (response.success) {
            successToast("Location successfully created.");
            navigate(appPaths.locations.index);
        }


        return response;
    };

    return (
        <FormContainer>
            <LocationCreateForm onSubmit={onSubmit} isSubmitting={isCreatingLocation} />
        </FormContainer>
    );
};

export default LocationCreatePage;
