import { Box, Button, DialogActions, Divider, Typography, useTheme } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import FormCard from "../FormCard";
import { useAppPaths } from "../../Routes";
import CreatedByDisplay from "../CreatedByDisplay";
import { LoadingButton } from "@mui/lab";
import ApiError from "../../api/common/apiError";
import { useState } from "react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import FormErrors from "../FormErrors";
import { useOrganizationConfig } from "../../api/organization";
import { RoleAssignmentDetailsDto, roleAssignmentStatusOptions, roleAssignmentStatusTypes } from "../../api/models/roleAssignment";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import DialogBox from "../DialogBox/DialogBox";
import useDialogBoxState from "../DialogBox/useDialogBoxState";
import FormTextInput from "../FormTextInput";
import { FormProvider } from "react-hook-form";
import FormInputLabel from "../FormInputLabel";
import { RejectRoleAssignmentFormValues, useRejectRoleAssignmentForm } from "./RejectRoleAssignmentFormValues";

export type RoleAssignmentViewPageProps = {
    roleAssignment: RoleAssignmentDetailsDto
    onApprove: () => Promise<GigApiFetcherResponse<void>>
    onReject: (reason: RejectRoleAssignmentFormValues) => Promise<GigApiFetcherResponse<void>>
    onCancel: (giggedClientId: string) => Promise<GigApiFetcherResponse<void>>
    isApproving: boolean
    isRejecting: boolean
    isCancelling: boolean
}

const RoleAssignmentViewPage = ({
    roleAssignment,
    onApprove,
    onReject,
    onCancel,
    isApproving,
    isRejecting,
    isCancelling,
}: RoleAssignmentViewPageProps) => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const { giggedClientTerminology, talentTerminology } = useOrganizationConfig();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const isLargeScreen = useIsLargeScreen();
    const methods = useRejectRoleAssignmentForm();
    const rejectDialogBoxState = useDialogBoxState();

    const handleCloseButton = () => {
        navigate(-1);
    };

    const handleApprove = async () => {
        setSubmissionError(undefined);

        const response = await onApprove();

        if (!response.success) setSubmissionError(response.error);
    };

    const handleReject = async (values: RejectRoleAssignmentFormValues) => {
        setSubmissionError(undefined);

        const response = await onReject(values);

        if (response.success) {
            rejectDialogBoxState.close();
        } else {
            setSubmissionError(response.error);
        }
    };

    const handleCancel = async () => {
        setSubmissionError(undefined);

        const response = await onCancel(roleAssignment.giggedClientId);

        if (!response.success) setSubmissionError(response.error);
    };

    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleCancel();
        }
    });

    const handleRejectDialogClose = () => {
        methods.reset();
        rejectDialogBoxState.close();
    };

    const roleAssignmentStatus = roleAssignmentStatusOptions[roleAssignment.roleAssignmentStatusId];
    const isPending = roleAssignmentStatus.value === roleAssignmentStatusTypes.pending;
    const isCancellable = [roleAssignmentStatusTypes.pending, roleAssignmentStatusTypes.submitted].includes(roleAssignmentStatus.value);

    return (
        <>
            <FormCard title="Role assignment" onClose={handleCloseButton}>
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(2),
                    }
                }}>
                    <CreatedByDisplay createdAt={roleAssignment.dateSent} />
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {talentTerminology} name: <Link className="ml-2 font-normal underline" to={appPaths.talents.edit(roleAssignment.talentId)}>{roleAssignment.talentName}</Link>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Role Assignment ID: <Typography sx={{ marginLeft: "8px" }} component="span">{roleAssignment.id}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Status: <Typography sx={{ marginLeft: "8px" }} component="span">{roleAssignmentStatus.label}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Message: <Typography sx={{ marginLeft: "8px" }} component="span">{roleAssignment.message}</Typography>
                    </Typography>

                    <Divider />

                    <Typography fontWeight="bold" component="label" sx={{ display: "block", textDecoration: "underline" }}>
                        Current role
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Role: {roleAssignment.talentCurrentRoleTitle ?
                            <Link className="ml-2 font-normal underline" to={appPaths.roles.view(roleAssignment.roleId)}>
                                {roleAssignment.talentCurrentRoleTitle}
                            </Link>
                            : <span className="font-normal">N/A</span>}
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {giggedClientTerminology}: {roleAssignment.talentCurrentGiggedClientName ?
                            <Link className="ml-2 font-normal underline" to={appPaths.clients.edit(roleAssignment.giggedClientId)}>
                                {roleAssignment.talentCurrentGiggedClientName}
                            </Link>
                            : <span className="font-normal">N/A</span>}
                    </Typography>

                    <Divider />

                    <Typography fontWeight="bold" component="label" sx={{ display: "block", textDecoration: "underline" }}>
                        Prospective role
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Role: <Link className="ml-2 font-normal underline" to={appPaths.roles.view(roleAssignment.roleId)}>{roleAssignment.roleTitle}</Link>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {giggedClientTerminology}: <Link className="ml-2 font-normal underline" to={appPaths.clients.edit(roleAssignment.giggedClientId)}>{roleAssignment.giggedClientName}</Link>
                    </Typography>

                    <FormErrors messages={submissionError?.userMessages} />
                    <Box sx={{
                        display: "flex",
                        justifyContent: isLargeScreen ? "space-between" : "flex-end",
                        flexDirection: isLargeScreen ? "row" : "column-reverse",
                        marginTop: 4,
                        gap: isLargeScreen ? 2 : undefined
                    }}>
                        {isCancellable && (
                            <LoadingButton
                                disabled={isCancelling}
                                loading={isCancelling}
                                color="secondary"
                                onClick={openCancelDialog}
                                className={`!mt-4 sm:!mt-0 !mr-auto w-full ${isLargeScreen ? "sm:w-auto" : ""}`}
                            >
                                Cancel assignment
                            </LoadingButton>
                        )}
                        {isPending && (
                            <div className={`flex ${isLargeScreen ? "flex-row" : "flex-col-reverse"} gap-4 ml-auto w-full ${isLargeScreen ? "sm:w-auto" : ""}`}>
                                <LoadingButton
                                    disabled={isRejecting}
                                    loading={isRejecting}
                                    color="secondary"
                                    onClick={rejectDialogBoxState.open}
                                    variant="outlined"
                                >
                                    Reject
                                </LoadingButton>
                                <LoadingButton
                                    disabled={isApproving}
                                    loading={isApproving}
                                    color="primary"
                                    onClick={handleApprove}
                                    variant="contained"
                                >
                                    Approve
                                </LoadingButton>
                            </div>
                        )}
                    </Box>
                </Box>
            </FormCard>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Are you sure you want to cancel this assignment? This action cannot be undone."
                confirmButtonText="Cancel assignment"
                cancelButtonText="Back"
            />
            <DialogBox
                {...rejectDialogBoxState}
                isOpen={rejectDialogBoxState.isOpen}
                onClose={handleRejectDialogClose}
                title="Reject role assignment"
                fullWidth
            >
                <FormProvider {...methods} >
                    <form
                        onSubmit={methods.handleSubmit(handleReject)}
                        noValidate
                    >
                        <Box className="space-y-2">
                            <FormInputLabel required>Reason</FormInputLabel>
                            <FormTextInput
                                required
                                multiline
                                rows={3}
                                name="reason"
                            />
                        </Box>
                        <Box sx={{ marginTop: "1.5rem" }}>
                            <DialogActions>
                                <Box className="flex flex-col md:flex-row-reverse md:justify-between md:items-center md:space-x-2 w-full space-y-2 md:space-y-0">
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        loading={isRejecting}
                                    >
                                        Confirm
                                    </LoadingButton>
                                    <Button type="button" variant="text" color="secondary" disabled={isRejecting} onClick={handleRejectDialogClose}>Cancel</Button>
                                </Box>
                            </DialogActions>
                        </Box>
                    </form>
                </FormProvider>
            </DialogBox>
        </>
    );
};

export default RoleAssignmentViewPage;