import { useEffect } from "react";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Link } from "react-router-dom";

import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { useAppPaths } from "../../Routes";
import formatDateOnlyString from "../../utils/formatDateString";
import { useOrganizationConfig } from "../../api/organization";
import StatusCell from "../GigsTable/GigStatusCell";
import { useRoleAssignments } from "../../api/roleAssignments";
import { roleAssignmentStatusOptions } from "../../api/models/roleAssignment";

export type RoleAssignmentsTableProps = {
    searchTerm?: string
}

const RoleAssignmentsTable = ({ searchTerm }: RoleAssignmentsTableProps) => {
    const roleAssignments = useRoleAssignments();
    const appPaths = useAppPaths();
    const { giggedClientTerminology, talentTerminology } = useOrganizationConfig();

    const setSearchTerm = roleAssignments.setSearchTerm;

    const handleOrderByChange = (property: string) => {
        const isAsc = roleAssignments.orderBy === property && roleAssignments.sortOrder === "asc";
        roleAssignments.setSortOrder(isAsc ? "desc" : "asc");
        roleAssignments.setOrderBy(property);
    };

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    if (roleAssignments.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "RoleTitle",
                        label: "Role title",
                        isSortable: true,
                    },
                    {
                        key: "GiggedClientName",
                        label: giggedClientTerminology,
                        isSortable: true,
                    },
                    {
                        key: "TalentName",
                        label: `${talentTerminology} name`,
                        isSortable: true,
                    },
                    {
                        key: "RoleAssignmentStatusId",
                        label: "Status",
                        isSortable: true,
                    },
                    {
                        key: "DateSent",
                        label: "Date created",
                        isSortable: true,
                    },
                    {
                        label: "",
                        isSortable: false
                    },
                ]}
                data={roleAssignments.roleAssignments}
                renderRow={roleAssignment => (
                    <>

                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{roleAssignment.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{roleAssignment.roleTitle}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{roleAssignment.giggedClientName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{roleAssignment.talentName}</TableCell>
                        <StatusCell statusId={roleAssignment.roleAssignmentStatusId} options={roleAssignmentStatusOptions} />
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(roleAssignment.dateSent)}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.roles.assignments.view(roleAssignment.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={roleAssignments.setPageIndex}
                setPageSize={roleAssignments.setPageSize}
                pageSize={roleAssignments.pageSize}
                pageIndex={roleAssignments.pageIndex}
                totalCount={roleAssignments.totalCount}
                orderBy={roleAssignments.orderBy}
                sortOrder={roleAssignments.sortOrder}
                isLoading={roleAssignments.isLoading}
            />
        </TableCard>
    );
};

export default RoleAssignmentsTable;
