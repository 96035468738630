import FormRadioInput from "../FormRadioInput";

export type IsAdminInputProps = {
    disabled: boolean
    isCurrentUser?: boolean
}

const IsAdminInput = ({
    disabled,
    isCurrentUser
}: IsAdminInputProps) => {
    return (
        <FormRadioInput
            name="isAdmin"
            label="Is Admin?"
            helperText={isCurrentUser ? "You cannot edit your own admin status" : undefined}
            disabled={disabled}
            options={[
                {
                    value: 1,
                    label: "Yes",
                },
                {
                    value: 0,
                    label: "No",
                },
            ]}
        />
    );
};

export default IsAdminInput;