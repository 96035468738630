import { useState } from "react";
import { useDebounce } from "use-debounce";
import { Box, useTheme } from "@mui/material";

import SearchBox from "../SearchBox";
import { useOrganizationConfig } from "../../api/organization";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import ApplicationsTable from "./ApplicationsTable";
import { useApplications } from "../../api/applications";

const ApplicationsTableWithSearch = () => {
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const [searchTerm, setSearchTerm] = useState("");
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);
    const applications = useApplications();
    const { talentTerminology } = useOrganizationConfig();

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                "> *:not(:first-of-type)": {
                    marginLeft: 1
                },
            }}>
                <SearchBox
                    placeholder={isLargeScreen ? `Search by Role or ${talentTerminology} name` : "Search Applications"}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={isLargeScreen ?  { minWidth: "28rem", background: "#fff" } : { flexGrow: 1, background: "#fff" }}
                />
        
            </Box>
            <Box sx={{ marginTop: "1.5rem" }}>
                <ApplicationsTable 
                    applications={applications}
                    searchTerm={debounceSearchTerm}
                />
            </Box>
        </>
    );
};

export default ApplicationsTableWithSearch;