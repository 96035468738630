import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { CreateUserDto, UserSummaryDto } from "./models/user";

export const useCreateUser = () => useAuthenticatedGigApiFetcher<CreateUserDto, string, CreateUserDto>("POST",
    (dto) => ({
        path: "api/users",
        body: dto
    }));

export const useUsers= (initialPageSize = 10) => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("LastName");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [isAdminFilter, setIsAdminFilter] = useState<boolean | undefined>();
    const [isArchivedFilter, setIsArchivedFilter] = useState<boolean | undefined>();

    const url = "api/users?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&` +
        (isAdminFilter === undefined ? "" : `isAdmin=${isAdminFilter}&`) +
        (isArchivedFilter === undefined ? "" : `isArchived=${isArchivedFilter}`);

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<UserSummaryDto>>(url);
   
    const [createUser, isCreatingUser] = useCreateUser();

    return {
        ...apiCall,
        ...apiCall.data,
        data: apiCall.data?.items || [],
        orderBy,
        sortOrder,
        setSearchTerm,
        setOrderBy,
        setSortOrder,
        setPageIndex,
        setPageSize,
        setIsAdminFilter,
        setIsArchivedFilter,
        createUser: async (dto: CreateUserDto) => {
            const response = await createUser(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isCreatingUser
    };
};