import { Controller, FormProvider } from "react-hook-form";
import { Box, Button, DialogActions } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";

import DialogBox from "../DialogBox/DialogBox";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { ClientAddTalentFormValues, useAddTalentForm } from "./ClientAddTalentFormValues";
import { useOrganizationConfig } from "../../api/organization";
import FormInputLabel from "../FormInputLabel";
import FormErrors from "../FormErrors";
import { useState } from "react";
import ApiError from "../../api/common/apiError";
import { clientTooltips } from "../../tooltipsContent";
import TalentSearchDropdown from "../TalentSearchDropdown";

export type ClientAddTalentFormProps = {
    isOpen: boolean
    onClose: () => void
    handleAddTalent: (values: ClientAddTalentFormValues) => Promise<GigApiFetcherResponse<void>>
    isAdding: boolean
    disabledTalentIds?: string[]
}

const ClientAddTalentFormDialog = ({
    isOpen,
    onClose,
    handleAddTalent,
    isAdding,
    disabledTalentIds
}: ClientAddTalentFormProps) => {
    const methods = useAddTalentForm();
    const { handleSubmit, formState: { isDirty, isValidating } } = methods;
    const isLargeScreen = useIsLargeScreen();
    const { talentTerminology, talentTerminologyPlural, giggedClientTerminology } = useOrganizationConfig();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();

    const onSubmit = async (values: ClientAddTalentFormValues) => {
        const response = await handleAddTalent(values);
        if (!response.success) setSubmissionError(response.error);

        return response;
    };

    return (

        <DialogBox isOpen={isOpen}>
            <Box sx={isLargeScreen ? { width: "28rem" } : { width: "15rem" }}>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                        className="min-h-[500px] md:min-h-[350px] flex justify-between flex-col"
                    >
                        <Box className="space-y-2 flex flex-col">
                            <FormInputLabel tooltipText={clientTooltips.addTalentToClient(talentTerminologyPlural, giggedClientTerminology)} required>Add {talentTerminology.toLowerCase()}</FormInputLabel>
                            <Controller
                                name="talentId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <TalentSearchDropdown
                                        error={methods.formState.errors.talentId}
                                        onChange={onChange}
                                        value={value}
                                        isSubmitting={isAdding}
                                        disabledTalentIds={disabledTalentIds}
                                    />
                                )}
                            />
                            <FormErrors messages={submissionError?.userMessages} />
                        </Box>
                        <Box className="mt-6">
                            <DialogActions>
                                <Box className="flex flex-col md:flex-row-reverse md:justify-between md:items-center md:space-x-2 w-full space-y-2 md:space-y-0">
                                    <LoadingButton
                                        variant="contained"
                                        startIcon={<SaveOutlinedIcon sx={!isDirty || isAdding ? { opacity: "26%" } : { opacity: "100%" }} />}
                                        type="submit"
                                        disabled={!isDirty}
                                        loading={isAdding}
                                    >
                                        Save changes
                                    </LoadingButton>
                                    <Button type="button" variant="text" color="secondary" disabled={isAdding || isValidating} onClick={onClose}>Cancel</Button>
                                </Box>
                            </DialogActions>
                        </Box>
                    </form>
                </FormProvider>
            </Box>
        </DialogBox>
    );
};

export default ClientAddTalentFormDialog;