import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useRoleCategoryId } from "../Routes";
import { useRoleCategory } from "../api/roleCategory";
import { RoleCategoryEditForm, RoleCategoryEditFormValues } from "../components/RoleCategoryEditForm/";

const RoleCategoryEditPage = () => {
    const roleCategoryId = useRoleCategoryId();
    const roleCategory = useRoleCategory(roleCategoryId as string);

    const onSubmit = async (values: RoleCategoryEditFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await roleCategory.updateRoleCategory(values);

        if (response.success) {
            successToast("Category successfully updated.");
        }

        return response;
    };

    if (roleCategory.isLoading || !roleCategory.roleCategory) return <Loader />;

    return (
        <FormContainer>
            <RoleCategoryEditForm
                initialValues={roleCategory.roleCategory}
                onSubmit={onSubmit}
                isSubmitting={roleCategory.isUpdatingRoleCategory}
            />
        </FormContainer>
    );
};

export default RoleCategoryEditPage;
