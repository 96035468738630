export type ProfileImageThumbnailProps = {
    name: string
    userImageUrl?: string
}

const ProfileImageThumbnail = ({
    name,
    userImageUrl
}: ProfileImageThumbnailProps) => {

    return (
        <div>
            {userImageUrl ? (
                <div className="flex items-center justify-center mx-auto mb-2 rounded-full">
                    <img
                        src={userImageUrl}
                        className="h-10 w-10 object-cover rounded-full shrink-0 min-w-[2.5rem]"
                    />
                </div>
            ) :
                <div className="h-10 w-10 text-[16px] flex items-center justify-center mx-auto rounded-full bg-surface">
                    {name[0].toUpperCase()}
                </div>
            }
        </div>
    );
};

export default ProfileImageThumbnail;
