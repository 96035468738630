import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export type RejectRoleAssignmentFormValues = {
    reason: string
};

export const rejectRoleAssignmentFormValidationSchemaFields = () => {
    return yup.object({
        reason: yup.string().required("Reason is required."),
    });
};

export const useRejectRoleAssignmentForm = () => {
    const schema = rejectRoleAssignmentFormValidationSchemaFields();
    return useForm<RejectRoleAssignmentFormValues>({
        resolver: yupResolver(schema)
    });
};