import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useClients } from "../../api/clients";
import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import { useAppPaths } from "../../Routes";
import formatDateString from "../../utils/formatDateString";

export type ClientsTableProps = {
    searchTerm?: string
}

const ClientsTable = ({
    searchTerm
}: ClientsTableProps) => {
    const appPaths = useAppPaths();
    const clients = useClients();
    const { giggedClientTerminology, giggedClientTerminologyPlural, talentTerminology, gigDashboardDomain } = useOrganizationConfig();
    const { setSearchTerm } = clients;

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    const handleOrderByChange = (property: string) => {
        const isAsc = clients.orderBy === property && clients.sortOrder === "asc";
        clients.setSortOrder(isAsc ? "desc" : "asc");
        clients.setOrderBy(property);
    };

    const headers = [
        {
            label: "ID",
            isSortable: false,
        },
        {
            key: "Name",
            label: `${giggedClientTerminology} name`,
            isSortable: true,
        },
        {
            label: `${talentTerminology} count`,
            isSortable: false,
        },
        {
            label: "User email",
            isSortable: false,
        },
        {
            label: "User full name",
            isSortable: false,
        },
        {
            key: "CreatedAt",
            label: "Date created",
            isSortable: true
        },
        {
            label: "View profile",
            isSortable: false
        },
        {
            label: "",
            isSortable: false
        }
    ];

    if (clients.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={headers}
                data={clients.clients}
                renderRow={client => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{client.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{client.name || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{client.talentsCount}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{client.userEmail || "-"}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{client.userFirstName || "-"} {client.userLastName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateString(client.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                            <a href={`https://${gigDashboardDomain}/${giggedClientTerminologyPlural.toLowerCase()}/${client.id}`} target="_blank">
                                <VisibilityIcon color="secondary" />
                            </a>
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.clients.edit(client.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={clients.setPageIndex}
                setPageSize={clients.setPageSize}
                pageSize={clients.pageSize}
                pageIndex={clients.pageIndex}
                totalCount={clients.totalCount}
                orderBy={clients.orderBy}
                sortOrder={clients.sortOrder}
                isLoading={clients.isLoading}
            />
        </TableCard>
    );
};

export default ClientsTable;