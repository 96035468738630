import { useEffect, useState } from "react";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { CancelRoleAssignmentDto, RejectRoleAssignmentDto, RoleAssignmentDetailsDto } from "./models/roleAssignment";
import { ApiRoleAssignmentDetailsDto } from "./models/api/roleAssignment";
import { mapFromApiRoleAssignmentDetailsDto } from "../mappers/roleAssignment";

export const useApproveRoleAssignment = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (roleAssignmentId) => ({
        path: `api/role-assignments/${roleAssignmentId}/actions/approve`,
    }));

export const useRejectRoleAssignment = () => useAuthenticatedGigApiFetcher<RejectRoleAssignmentDto, void, { roleAssignmentId: string, dto: RejectRoleAssignmentDto }>("PUT",
    ({ roleAssignmentId, dto}) => ({
        path: `api/role-assignments/${roleAssignmentId}/actions/reject`,
        body: dto
    }));
    
export const useCancelRoleAssignment = () => useAuthenticatedGigApiFetcher<CancelRoleAssignmentDto, void, { roleAssignmentId: string, dto: CancelRoleAssignmentDto }>("PUT",
    ({roleAssignmentId, dto}) => ({
        path: `api/role-assignments/${roleAssignmentId}/actions/cancel`,
        body: dto
    }));

export type UseRoleAssignmentReturn<TGigId extends string | undefined> = TGigId extends undefined ?
    { roleAssignment: undefined } :
    {
        roleAssignment: RoleAssignmentDetailsDto
        approveRoleAssignment: () => Promise<GigApiFetcherResponse<void>>
        rejectRoleAssignment: (dto: RejectRoleAssignmentDto) => Promise<GigApiFetcherResponse<void>>
        cancelRoleAssignment: (dto: CancelRoleAssignmentDto) => Promise<GigApiFetcherResponse<void>>
        isLoading: boolean
        isApprovingRoleAssignment: boolean
        isRejectingRoleAssignment: boolean
        isCancellingRoleAssignment: boolean
    }

export const useRoleAssignment = <TRoleAssignmentId extends string | undefined>(roleAssignmentId?: TRoleAssignmentId): UseRoleAssignmentReturn<TRoleAssignmentId> => {
    const [roleAssignment, setRoleAssignment] = useState<RoleAssignmentDetailsDto | undefined>();
    const [approveRoleAssignment, isApprovingRoleAssignment] = useApproveRoleAssignment();
    const [rejectRoleAssignment, isRejectingRoleAssignment] = useRejectRoleAssignment();
    const [cancelRoleAssignment, isCancellingRoleAssignment] = useCancelRoleAssignment();
    
    const url = `api/role-assignments/${roleAssignmentId}`;

    const apiCall = useGiggedApiSWR<ApiRoleAssignmentDetailsDto>(url);

    useEffect(() => {
        if (apiCall.data === undefined) {
            setRoleAssignment(undefined);
            return;
        } 
        
        setRoleAssignment(mapFromApiRoleAssignmentDetailsDto(apiCall.data));
    }, [apiCall.data]);

    if (roleAssignmentId === undefined) return { roleAssignment: undefined } as UseRoleAssignmentReturn<TRoleAssignmentId>;

    return {
        ...apiCall,
        roleAssignment,
        isLoading: apiCall.isLoading,
        approveRoleAssignment: async () => {
            const response = await approveRoleAssignment(roleAssignmentId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        rejectRoleAssignment: async (dto: RejectRoleAssignmentDto) => {
            const response = await rejectRoleAssignment({roleAssignmentId, dto});

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        cancelRoleAssignment: async (dto: CancelRoleAssignmentDto) => {
            const response = await cancelRoleAssignment({roleAssignmentId, dto});

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isApprovingRoleAssignment, 
        isRejectingRoleAssignment,
        isCancellingRoleAssignment
    } as UseRoleAssignmentReturn<TRoleAssignmentId>;
};