import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProposalMilestoneDetailsDto } from "../../api/models/proposalMilestone";
import { useOrganizationConfig } from "../../api/organization";
import { useAppPaths } from "../../Routes";
import formatDateOnlyString from "../../utils/formatDateString";
import CreatedByDisplay from "../CreatedByDisplay";
import EditButton from "../EditButton";
import FormCard from "../FormCard";

export type ProposalMilestoneViewPageProps = {
    proposalMilestone: ProposalMilestoneDetailsDto
}
const ProposalMilestoneViewPage = ({
    proposalMilestone
}: ProposalMilestoneViewPageProps) => {
    const { gigTerminology, talentTerminology } = useOrganizationConfig();
    const theme = useTheme();
    const navigate = useNavigate();
    const appPaths = useAppPaths();

    const handleEditMilestone = () => {
        navigate(appPaths.milestones.edit(proposalMilestone.id));
    };

    const handleCloseButton = () => {
        navigate(appPaths.proposals.milestones(proposalMilestone.proposalId));
    };

    return (
        <>
            <FormCard
                title={`${gigTerminology} milestone`}
                onClose={handleCloseButton}
            >
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <CreatedByDisplay createdBy={proposalMilestone.talentName} createdAt={proposalMilestone.createdAt} updatedAt={proposalMilestone.lastUpdatedAt} />

                    <Divider />

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Milestone ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.id}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Milestone description: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.description}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Date milestone completed: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.dateCompleted ? formatDateOnlyString(proposalMilestone.dateCompleted.toISOString()) : "-"}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {gigTerminology} ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.gigId}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {gigTerminology} title: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.gigTitle}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Proposal ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.proposalId}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {talentTerminology} name: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalMilestone.talentName}</Typography>
                    </Typography>
                    <Box className="flex justify-end items-center">
                        <EditButton
                            buttonText={"Edit milestone"}
                            onClick={handleEditMilestone}
                            disabled={false}
                        />
                    </Box>
                </Box>
            </FormCard>
        </>
    );
};

export default ProposalMilestoneViewPage;