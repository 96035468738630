import { FieldError } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useState } from "react";

import FormSearchDropdown, { FormSearchDropdownOption, LoadOptionsCallback } from "./FormSearchDropdown/FormSearchDropdown";
import useAccessToken from "../auth/useAccessToken";
import { fetchTalents } from "../api/talents";

export type TalentSearchDropdownProps = {
    tooltip?: string
    value: string
    onChange: (value: string, option: FormSearchDropdownOption | null) => void
    isSubmitting: boolean
    error?: FieldError
    disabledTalentIds?: string[]
}

const TalentSearchDropdown = ({
    tooltip,
    value,
    onChange,
    isSubmitting,
    error,
    disabledTalentIds
}: TalentSearchDropdownProps) => {
    const accessToken = useAccessToken();

    const [allTalentOptions, setAllTalentOptions] = useState<FormSearchDropdownOption[]>([]);

    const fetchTalentOptions = async (searchQuery: string) => {
        const talentsResponse = await fetchTalents(searchQuery, accessToken);

        return talentsResponse
            .map(talent => ({
                value: talent.id,
                label: `${talent.firstName} ${talent.lastName} (${talent.email})`,
            }));
    };

    useEffect(() => {
        if (!accessToken) return;

        const loadAllTalents = async () => {
            const fetchedTalentOptions = await fetchTalentOptions("");
            setAllTalentOptions(fetchedTalentOptions);
        };

        loadAllTalents();
    }, [accessToken]);

    const loadTalentOptions = (inputValue: string, callback: LoadOptionsCallback) => {
        fetchTalentOptions(inputValue)
            .then(options => callback(options));
    };

    const debouncedLoadSkillOptions = useDebouncedCallback(loadTalentOptions, 700);

    return (
        <>
            <FormSearchDropdown
                tooltip={tooltip}
                value={value}
                loadOptions={debouncedLoadSkillOptions}
                onChange={onChange}
                defaultOptions={allTalentOptions}
                disabled={isSubmitting}
                required={true}
                error={error}
                disabledOptionIds={disabledTalentIds}
            />
        </>
    );
};

export default TalentSearchDropdown;