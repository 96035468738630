import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useSkillId } from "../Routes";
import { useSkill } from "../api/skill";
import { SkillEditForm, SkillEditFormValues } from "../components/SkillEditForm";

const SkillEditPage = () => {
    const skillId = useSkillId();
    const skill = useSkill(skillId as string);

    const onSubmit = async (values: SkillEditFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await skill.updateSkill(values);

        if (response.success) {
            successToast("Skill successfully updated.");
        }

        return response;
    };

    const handleDelete = async () => {
        const response = await skill.deleteSkill();

        if (response.success) {
            successToast("Skill successfully deleted.");
        }

        return response;
    };

    if (skill.isLoading || !skill.skill) return <Loader />;

    return (
        <FormContainer>
            <SkillEditForm
                initialValues={skill.skill}
                onSubmit={onSubmit}
                onDelete={handleDelete}
                isSubmitting={skill.isUpdatingSkill}
                isDeleting={skill.isDeletingSkill}
            />
        </FormContainer>
    );
};

export default SkillEditPage;
