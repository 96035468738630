import { useEffect, useState } from "react";
import { Controller, FormProvider, UseFormReturn } from "react-hook-form";
import { Box, useTheme } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import FormTextInput from "../FormTextInput";
import ApiError from "../../api/common/apiError";
import FormErrors from "../FormErrors";
import ConfirmDialog from "../ConfirmDialogBox";
import { useAppPaths } from "../../Routes";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { SkillCreateFormValues } from "./SkillCreateFormValues";
import FormInputLabel from "../FormInputLabel";
import { skillsTooltips, talentTooltips } from "../../tooltipsContent";
import { SkillCategoryInput } from "../SkillCategoriesSearchDropdown";
import useCsvFileUploadInputState from "../CsvFileUploadInput/useCsvFileUploadInputState";
import CsvFileUploadInput from "../CsvFileUploadInput/CsvFileUploadInput";

type SkillCreateFormProps = {
    onSubmit: (values: SkillCreateFormValues) => Promise<GigApiFetcherResponse<string>>
    isSubmitting: boolean
    handleSubmitBulkSkills: (file: File) => Promise<GigApiFetcherResponse<string>>
    methods: UseFormReturn<SkillCreateFormValues>
}

const SkillCreateForm = ({
    onSubmit,
    isSubmitting,
    handleSubmitBulkSkills,
    methods,
}: SkillCreateFormProps) => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.skills.categories.index);
        }
    });

    const {
        loadFile,
        file,
        reset: resetFileInput,
        error: fileError,
        isDirty: isFileDirty,
        ...fileInputProps
    } = useCsvFileUploadInputState({
        maxFileSizeKb: 1000,
    });

    const {
        formState: { isDirty, errors }
    } = methods;

    const handleSubmit = async (values: SkillCreateFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) {
            setSubmissionError(response.error);
        }
    };

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.skills.index);
        }
    };

    const handleBulkUploadSubmit = async () => {
        if (!file) return;

        const response = await handleSubmitBulkSkills(file);

        if (!response.success) {
            setSubmissionError(response.error);
            return;
        }

        resetFileInput();
    };

    useEffect(() => {
        if (file === undefined) return;
        setSubmissionError(undefined);
    }, [file]);

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    noValidate
                >
                    <FormCard onClose={handleCloseButton} title="Add Skills">
                        <Box sx={{
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            }
                        }}>
                            <FormTextInput name="skillName" label="Skill name" disabled={isSubmitting} required />
                            {methods.formState.dirtyFields.skillName && <FormErrors messages={submissionError?.userMessages} />}
                            <Box className="space-y-2 flex flex-col">
                                <>
                                    <FormInputLabel tooltipText={talentTooltips.talentSkillCategory}>Skill category</FormInputLabel>
                                    <Controller
                                        name="skillCategoryId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <SkillCategoryInput
                                                tooltip={talentTooltips.talentSkillCategory}
                                                error={errors.skillCategoryId}
                                                onChange={onChange}
                                                value={value}
                                                isSubmitting={isSubmitting}
                                            />
                                        )}
                                    />
                                </>
                            </Box>
                            <Box sx={
                                isLargeScreen ?
                                    { display: "flex", justifyContent: "flex-end" } :
                                    { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "2rem" }
                            }>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                >
                                    Add skill
                                </LoadingButton>
                            </Box>
                            <CsvFileUploadInput
                                inputId="bulk-skills-upload-button"
                                label="Bulk skills upload"
                                helpText="Use this tool to import multiple skills from a CSV file."
                                tooltip={skillsTooltips.bulkUpload}
                                onLoadFile={loadFile}
                                error={fileError}
                                isDirty={isFileDirty}
                                {...fileInputProps}
                            />
                            {isFileDirty && <FormErrors messages={submissionError?.userMessages} />}

                            <Box sx={
                                isLargeScreen ?
                                    { display: "flex", justifyContent: "flex-end" } :
                                    { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "2rem" }
                            }>
                                <LoadingButton
                                    type="button"
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={isSubmitting || !isFileDirty ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting}
                                    disabled={!isFileDirty}
                                    onClick={handleBulkUploadSubmit}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                >
                                    Import now
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider >
            <ConfirmDialog
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default SkillCreateForm;