import React, { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { CreateRoleCategoryDto, RoleCategorySummary } from "./models/roleCategory";
import gigApiFetcher, { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";

export const useCreateRoleCategory = () => useAuthenticatedGigApiFetcher<CreateRoleCategoryDto, string, CreateRoleCategoryDto>("POST",
    (dto) => ({
        path: "api/roles/categories",
        body: dto
    }));

export const fetchRoleCategories = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100,
): Promise<RoleCategorySummary[]> => {
    const path = `api/roles/categories?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`;
    const response = await gigApiFetcher<never, GigApiPaginatedData<RoleCategorySummary>>({
        path: path,
        token: accessToken || undefined
    });

    return response.items;
};

export type UseRoleCategoriesReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<RoleCategorySummary>>, "data"> & {
        roleCategories: RoleCategorySummary[]
        orderBy: string
        sortOrder: SortOrder
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        createRoleCategory: (dto: CreateRoleCategoryDto) => Promise<GigApiFetcherResponse<string>>
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        isCreatingRoleCategory: boolean
    };

export const useRoleCategories = (initialPageSize = 10): UseRoleCategoriesReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("Name");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const [createRoleCategory, isCreatingRoleCategory] = useCreateRoleCategory();


    const url = "api/roles/categories?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}`;


    const apiCall = useGiggedApiSWR<GigApiPaginatedData<RoleCategorySummary>>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        roleCategories: apiCall.data?.items || [],
        orderBy,
        sortOrder,
        createRoleCategory: async (dto: CreateRoleCategoryDto) => {
            const response = await createRoleCategory(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        setSearchTerm,
        setOrderBy,
        setSortOrder,
        setPageIndex,
        setPageSize,
        isCreatingRoleCategory
    };
};