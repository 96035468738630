import { useNavigate, useParams } from "react-router-dom";

import { useClient } from "../api/client";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { ClientEditForm, ClientEditFormValues } from "../components/ClientEditForm";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useOrganizationConfig } from "../api/organization";

const ClientEditPage = () => {
    const appPaths = useAppPaths();
    const { clientId } = useParams<{ clientId: string }>();
    const { updateClient, isUpdatingClient, isLoading, client } = useClient(clientId as string);
    const navigate = useNavigate();
    const { giggedClientTerminology } = useOrganizationConfig();
    
    const onSubmit = async (values: ClientEditFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await updateClient(values);

        if (response.success) {
            successToast(`${giggedClientTerminology} successfully updated.`);
            navigate(appPaths.clients.index);
        }

        return response;
    };

    if (isLoading) return <Loader />;

    return (
        <FormContainer>
            {client && (
                <ClientEditForm
                    initialValues={client}
                    onSubmit={onSubmit}
                    isSubmitting={isUpdatingClient}
                />
            )}
        </FormContainer>
    );
};

export default ClientEditPage;
