import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Box, MenuItem, Select } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { TalentDetailsDto } from "../../api/models/talent";
import { useTalent } from "../../api/talent";
import ApiError from "../../api/common/apiError";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { TalentEditFormValues, useEditTalentForm } from "./TalentEditFormValues";
import { useOrganizationConfig } from "../../api/organization";
import FormInputLabel from "../FormInputLabel";
import { talentTooltips } from "../../tooltipsContent";
import { useAgencies } from "../../api/agencies";

export type TalentEditFormProps = {
    onSubmit: (values: TalentEditFormValues) => Promise<GigApiFetcherResponse<void>>
    initialValues: TalentDetailsDto
    isSubmitting: boolean
}

const TalentEditForm = ({
    initialValues,
    onSubmit,
    isSubmitting,
}: TalentEditFormProps) => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const { talent } = useTalent(initialValues.id);
    const methods = useEditTalentForm({
        ...initialValues,
        agencyId: initialValues?.agency?.id ?? "",
    });
    const { formState: { isDirty } } = methods;
    const { talentTerminology } = useOrganizationConfig();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.talents.index);
        }
    });
    const { organizationConfig } = useOrganizationConfig();
    const { agencies, isLoading } = useAgencies();

    const agencyOptions = agencies.map((agency) => ({ value: agency.id, label: agency.name }));

    useEffect(() => {
        methods.reset({
            ...initialValues,
            agencyId: initialValues?.agency?.id ? initialValues.agency.id : "None"
        });
    }, [initialValues]);

    const handleFormSubmit = async (values: TalentEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit({
            ...values,
            agencyId: values.agencyId === "None" ? null : values.agencyId
        });

        if (!response.success) setSubmissionError(response.error);
    };

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.talents.index);
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title={talent?.firstName + " " + talent?.lastName}>
                        <Box className="space-y-4">
                            <Box className="space-y-2 flex flex-col">
                                {organizationConfig?.isTalentAgencyEnabled && (
                                    <>
                                        <FormInputLabel tooltipText={talentTooltips.talentAgency(talentTerminology)}>Agency</FormInputLabel>
                                        {isLoading ? "Loading..." : (
                                            <Controller
                                                name="agencyId"
                                                control={methods.control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        value={value}
                                                        onChange={onChange}
                                                        variant="outlined"
                                                        color="secondary"
                                                        disabled={isSubmitting || isLoading}
                                                    >
                                                        <MenuItem value="None">None</MenuItem>
                                                        {
                                                            agencyOptions.length > 0 && agencyOptions.map(option => (
                                                                <MenuItem key={option?.value} value={option?.value}>{option?.label}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                            </Box>
                            <FormErrors messages={submissionError?.userMessages} />

                            <Box className="flex justify-end !mt-8">
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting}
                                    disabled={!isDirty}
                                    className="w-full md:w-auto"
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default TalentEditForm;