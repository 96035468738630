import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import gigApiFetcher, { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { CreateRoleDto, RoleSummaryDto } from "./models/role";

export const useCreateRole = () => useAuthenticatedGigApiFetcher<CreateRoleDto, string, CreateRoleDto>("POST",
    (dto) => ({
        path: "api/roles",
        body: dto
    }));

export const fetchRoles = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100,
): Promise<RoleSummaryDto[]> => {
    const path = `api/roles?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`;
    const response = await gigApiFetcher<never, GigApiPaginatedData<RoleSummaryDto>>({
        path: path,
        token: accessToken || undefined
    });

    return response.items;
};

export const fetchRoleOptions = async (query: string, accessToken: string | null) => {
    const rolesResponse = await fetchRoles(query, accessToken);

    return rolesResponse
        .map(role => ({
            value: role.id,
            label: role.title,
        }));
};

export type UseRolesReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<RoleSummaryDto>>, "data"> & {
        roles: RoleSummaryDto[]
        sortOrder:  SortOrder
        orderBy: string
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        createRole: (dto: CreateRoleDto) => Promise<GigApiFetcherResponse<string>>
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        setRoleStatusIds: (statusIds: number[]) => void
        isCreatingRole: boolean
    };

export const useRoles = (initialPageSize = 10): UseRolesReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("CreatedAt");
    const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [roleStatusIds, setRoleStatusIds] = useState<number[]>([]);

    const [createRole, isCreatingRole] = useCreateRole();

    const url = "api/roles?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&` +
        (roleStatusIds.length > 0 ? `&roleStatusIds=${roleStatusIds.join(",")}&` : "");

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<RoleSummaryDto>>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        roles: apiCall.data?.items || [],
        sortOrder,
        orderBy,
        createRole: async (dto: CreateRoleDto) => {
            const response = await createRole(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        setSearchTerm,
        setSortOrder,
        setOrderBy,
        setPageIndex,
        setPageSize,
        setRoleStatusIds,
        isCreatingRole
    };
};