import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { TalentSummaryDto } from "./models/talent";
import gigApiFetcher from "./common/fetching";

export const fetchTalents = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100,
): Promise<TalentSummaryDto[]> => {
    const path = `api/talents?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`;
    const response = await gigApiFetcher<never, GigApiPaginatedData<TalentSummaryDto>>({
        path: path,
        token: accessToken || undefined
    });

    return response.items;
};

export type UseTalentsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<TalentSummaryDto>>, "data"> & {
        talents: TalentSummaryDto[]
        sortOrder: SortOrder
        orderBy: string
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        setSkillIds: (skillId: string[]) => void
        setBadgeIds: (badgeId: string[]) => void
        setIsProfileCompleteFilter: (isProfileComplete?: boolean) => void
    };

export const useTalents = (initialPageSize = 10): UseTalentsReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("LastName");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [skillIds, setSkillIds] = useState<string[] | undefined>();
    const [badgeIds, setBadgeIds] = useState<string[] | undefined>();
    const [isProfileCompleteFilter, setIsProfileCompleteFilter] = useState<boolean | undefined>();

    const skillsQuery = skillIds && skillIds.length > 0 ? `&skillIds=${skillIds.join(",")}` : "";
    const badgesQuery = badgeIds && badgeIds.length > 0 ? `&badgeIds=${badgeIds.join(",")}` : "";

    const url = "api/talents?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&` +
        skillsQuery +
        badgesQuery +
        (isProfileCompleteFilter === undefined ? "" : `isProfileComplete=${isProfileCompleteFilter}&`);



    const apiCall = useGiggedApiSWR<GigApiPaginatedData<TalentSummaryDto>>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        talents: apiCall.data?.items || [],
        sortOrder,
        orderBy,
        setSearchTerm,
        setSortOrder,
        setOrderBy,
        setPageIndex,
        setPageSize,
        setSkillIds,
        setBadgeIds,
        setIsProfileCompleteFilter
    };
};