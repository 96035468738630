export type RoleSummaryDto = {
    id: string;
    title: string;
    roleCategoryId: string;
    roleCategoryName: string;
    roleStatusId: number
    giggedClientId: string
    giggedClientName: string
    skills: RoleSkill[]
    createdAt: string
    createdByUserName: string
    lastUpdatedAt: string
    lastUpdatedByUserName: string
    roleVisibilityTypeId: number
    roleAssignmentTypeId: number
    allowMultipleHires: boolean
    hiredTalents?: RoleHiredTalentsDto[]
}

export type RoleSkill = {
    id: string
    name: string
}

export type RoleDetailsDto = RoleSummaryDto & {
    description: string
    hiredApplications: RoleDetailsHiredApplications[]
}

export type CreateRoleDto = {
    title: string
    description: string
    roleCategoryId: string
    giggedClientId: string
    skillIds: string[]
    roleStatusId: number
}

export type UpdateRoleDto = CreateRoleDto & {
    openAfterSaving: boolean
}

export type AssignRoleDto = {
    giggedClientId: string
    talentId: string
    message: string
}

export type RoleHiredTalentsDto = {
    talentId: string
    talentFirstName: string
    talentLastName: string
}

export type RoleDetailsHiredApplications = {
    talentId: string
    talentFirstName: string
    talentLastName: string
}

export const roleStatuses = {
    inReview: 0,
    draft: 1,
    open: 2,
    active: 3,
    closed: 4,
    archived: 5,
};

export const roleStatusOptions = [
    { label: "In Review", value: 0 },
    { label: "Draft", value: 1 },
    { label: "Open", value: 2 },
    { label: "Active", value: 3 },
    { label: "Closed", value: 4 },
    { label: "Archived", value: 5 },
];

export const roleVisibilityOptions = [
    { label: "Department only", value: 0 },
    { label: "Organisation-wide", value: 1 }
];

export const roleAssignmentTypeOptions = [
    { label: "Open to applications", value: 0 },
    { label: "Assignment only", value: 1 },
];

export const roleAssignmentTypes = {
    applications: 0,
    assignment: 1
};

export const roleVisibilityTypes = {
    department: 0, 
    organisation: 1
};