import { useEffect, useState } from "react";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApplicationDetailsDto } from "./models/application";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";

export const useApproveApplication = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (applicationId) => ({
        path: `api/applications/${applicationId}/actions/approve`
    }));

export const useRejectApplication = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (applicationId) => ({
        path: `api/applications/${applicationId}/actions/reject`
    }));

export type UseApplicationReturn<TApplicationId extends string | undefined> = TApplicationId extends undefined ?
    { application: undefined } :
    {
        application: ApplicationDetailsDto
        approveApplication: () => Promise<GigApiFetcherResponse<void>>
        rejectApplication: () => Promise<GigApiFetcherResponse<void>>
        isApprovingApplication: boolean
        isRejectingApplication: boolean
        isLoading: boolean
    }

export const useApplication = <TApplicationId extends string | undefined>(applicationId: TApplicationId): UseApplicationReturn<TApplicationId> => {
    const url = `api/applications/${applicationId}`;
    const apiCall = useGiggedApiSWR<ApplicationDetailsDto>(url);

    const [approveApplication, isApprovingApplication] = useApproveApplication();
    const [rejectApplication, isRejectingApplication] = useRejectApplication();

    const [application, setApplication] = useState<ApplicationDetailsDto | undefined>();

    if (applicationId === undefined) return { application: undefined } as UseApplicationReturn<TApplicationId>;

    useEffect(() => {
        if (apiCall.data === undefined) {
            setApplication(undefined);
            return;
        }

        setApplication(apiCall.data);
    }, [apiCall.data]);

    return {
        ...apiCall,
        application,
        approveApplication: async () => {
            const response = await approveApplication(applicationId);

            if(response.success) apiCall.mutate();

            return response;
        },
        rejectApplication: async () => {
            const response = await rejectApplication(applicationId);

            if(response.success) apiCall.mutate();

            return response;
        },
        isApprovingApplication,
        isRejectingApplication,
        isLoading: apiCall.isLoading,
    } as UseApplicationReturn<TApplicationId>;
};