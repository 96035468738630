import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type ProposalMilestoneEditFormValues = {
    description: string
    dateCompleted: Date | null
}

const editProposalMilestoneSchema = () => {

    return yup
        .object({
            description: yup.string().required("Description is required."),
            dateCompleted: yup.date()
                .nullable()
                .optional()
                .typeError("Must be a valid date."),
        });
};

export const useEditProposalMilestoneForm = (initialValues?: ProposalMilestoneEditFormValues) => {
    const schema = editProposalMilestoneSchema();

    return useForm<ProposalMilestoneEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onBlur", 
        defaultValues: {
            description: initialValues?.description ?? "",
            dateCompleted: initialValues?.dateCompleted ?? null,
        }
    });
};