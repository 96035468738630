import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Box, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import ApiError from "../../api/common/apiError";
import ConfirmDialogBox from "../ConfirmDialogBox";
import FormTextInput from "../FormTextInput";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { RoleCategoryEditFormValues, useRoleCategoryEditForm } from "./RoleCategoryEditFormValues";
import { RoleCategoryDetailsDto } from "../../api/models/roleCategory";

export type RoleCategoryEditFormProps = {
    onSubmit: (values: RoleCategoryEditFormValues) => Promise<GigApiFetcherResponse<void>>
    initialValues: RoleCategoryDetailsDto
    isSubmitting: boolean
}

const RoleCategoryEditForm = ({
    onSubmit,
    initialValues,
    isSubmitting,
}: RoleCategoryEditFormProps) => {
    const appPaths = useAppPaths();
    const isLargeScreen = useIsLargeScreen();
    const navigate = useNavigate();
    const theme = useTheme();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const methods = useRoleCategoryEditForm({
        ...initialValues,
        roleCategoryName: initialValues.name
    });
    const { formState: { isDirty } } = methods;
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.roles.categories.index);
        }
    });

    useEffect(() => {
        methods.reset({
            ...initialValues,
            roleCategoryName: initialValues.name ?? "",
        });
    }, [methods.reset, initialValues]);

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.roles.categories.index);
        }
    };

    const handleFormSubmit = async (values: RoleCategoryEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title="Edit Role Category">
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: theme.spacing(2),

                        }}>
                            <Box className="space-y-2">
                                <FormTextInput
                                    required
                                    name="roleCategoryName"
                                    label="Role category name"
                                    disabled={isSubmitting}
                                />
                            </Box>
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "flex-end", marginTop: "2rem", gap: 2 } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "2rem" }
                        }>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                disabled={!isDirty}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Save changes
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default RoleCategoryEditForm;