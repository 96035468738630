import ApiError from "./common/apiError";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { AssignRoleDto, RoleDetailsDto, UpdateRoleDto } from "./models/role";

export const useUpdateRole = () => useAuthenticatedGigApiFetcher<UpdateRoleDto, void, { roleId: string, dto: UpdateRoleDto }>("PUT",
    ({ roleId, dto }) => ({
        path: `api/roles/${roleId}`,
        body: dto
    }));

export const useDeleteRole = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (roleId) => ({
        path: `api/roles/${roleId}`,
    }));

export const useAssignRole = () => useAuthenticatedGigApiFetcher<AssignRoleDto, void, { roleId: string, dto: AssignRoleDto }>("POST",
    ({ roleId, dto }) => ({
        path: `api/roles/${roleId}/assign`,
        body: dto
    }));

export type UseRoleReturn<TRoleId extends string | undefined> = TRoleId extends undefined ?
    { role: undefined } :
    {
        role?: RoleDetailsDto
        isLoading: boolean
        error?: ApiError
        updateRole: (dto: UpdateRoleDto) => Promise<GigApiFetcherResponse<void>>
        deleteRole: () => Promise<GigApiFetcherResponse<void>>
        assignRole: (dto: AssignRoleDto) => Promise<GigApiFetcherResponse<void>>
        isUpdatingRole: boolean
        isDeletingRole: boolean
        isAssigningRole: boolean
    }

export const useRole = <TRoleId extends string | undefined>(roleId: TRoleId): UseRoleReturn<TRoleId> => {
    const url = `api/roles/${roleId}`;

    const apiCall = useGiggedApiSWR<RoleDetailsDto>(url);

    const [updateRole, isUpdatingRole] = useUpdateRole();
    const [deleteRole, isDeletingRole] = useDeleteRole();
    const [assignRole, isAssigningRole] = useAssignRole();

    if (roleId === undefined) return { role: undefined } as UseRoleReturn<TRoleId>;

    return {
        role: apiCall.data,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateRole: async (dto: UpdateRoleDto) => {
            const response = await updateRole({ roleId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        deleteRole: async () => {
            const response = await deleteRole(roleId);

            return response;
        },
        assignRole: async (dto: AssignRoleDto) => {
            const response = await assignRole({ roleId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isUpdatingRole,
        isDeletingRole,
        isAssigningRole
    } as UseRoleReturn<TRoleId>;
};