import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { CreateLocationDto, LocationSummaryDto } from "./models/location";

export const useCreateLocation = () => useAuthenticatedGigApiFetcher<CreateLocationDto, string, CreateLocationDto>("POST",
    (dto) => ({
        path: "api/locations",
        body: dto
    }));

export const useLocations = (initialPageSize = 10) => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("Name");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const url = "api/locations?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&`;

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<LocationSummaryDto>>(url);

    const [createLocation, isCreatingLocation] = useCreateLocation();

    return {
        ...apiCall,
        ...apiCall.data,
        locations: apiCall.data?.items || [],
        orderBy,
        sortOrder,
        setSearchTerm,
        setOrderBy,
        setSortOrder,
        setPageIndex,
        setPageSize,
        createLocation: async (dto: CreateLocationDto) => {
            const response = await createLocation(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isCreatingLocation
    };
};