import { useMsal } from "@azure/msal-react";

export type UseCurrentUserReturn = {
    userGivenName: string
    userFamilyName: string
    email: string
    isClient: boolean
    idpUserId: string
}

const useCurrentUser = (): UseCurrentUserReturn => {
    const { accounts } = useMsal();

    const idTokenClaims = accounts.length > 0 ? accounts[0].idTokenClaims : undefined;
    const clientAccountStatus = idTokenClaims?.extension_giggedClientAccountStatus;
    const selectedAccountType = idTokenClaims?.extension_selectedAccountType;

    const isClient = 
    !!clientAccountStatus && clientAccountStatus !== "None" ||
    selectedAccountType === "giggedClient";
    
    return {
        userGivenName: idTokenClaims?.given_name as string || "",
        userFamilyName: idTokenClaims?.family_name as string || "",
        email: idTokenClaims?.email as string || "",
        isClient: isClient,
        idpUserId: idTokenClaims?.sub || ""
    };
};

export default useCurrentUser;