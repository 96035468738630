import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate, AuthenticatedTemplate } from "@azure/msal-react";
import { msalInstance } from ".";
import AuthContext from "./authContext";
import { useLoginRequest } from "./useLoginRequest";
import Loader from "../components/Loader";

export type AuthContextProviderProps = {
    children: React.ReactNode
}

const AuthContextProvider = ({
    children,
}: AuthContextProviderProps) => {
    const { loginRequest, isLoading } = useLoginRequest();

    if (isLoading || !loginRequest) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider value={{
            loginRequest
        }}>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                >
                    <AuthenticatedTemplate>
                        {children}
                    </AuthenticatedTemplate>                    
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;