import { useMediaQuery, Box, Typography, useTheme } from "@mui/material";
import ApplicationsTable from "../components/ApplicationsTable";

const ApplicationsListPage = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
    
    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 ) }}>
                <Typography variant="h4" gutterBottom>Applications</Typography>
                <ApplicationsTable />
            </Box>
        </>
    );
};

export default ApplicationsListPage;
