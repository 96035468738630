import { ApiRoleAssignmentDetailsDto, ApiRoleAssignmentSummaryDto } from "./api/roleAssignment";

export type RoleAssignmentSummaryDto = Omit<ApiRoleAssignmentSummaryDto, "roleAssignmentId"> & {
    id: string
}

export type RoleAssignmentDetailsDto = ApiRoleAssignmentDetailsDto & {
    id: string
}

export type CancelRoleAssignmentDto = {
    giggedClientId: string
}

export type RejectRoleAssignmentDto = {
    reason: string
}

export const roleAssignmentStatusOptions = [
    { label: "Submitted", value: 0 },
    { label: "Cancelled", value: 1 },
    { label: "Accepted", value: 2 },
    { label: "Completed", value: 3 },
    { label: "Pending", value: 4 },
    { label: "Rejected", value: 5 },
];

export const roleAssignmentStatusTypes = {
    submitted: 0,
    cancelled: 1,
    accepted: 2, 
    completed: 3,
    pending: 4,
    rejected: 5
};