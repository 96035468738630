import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { useAppPaths } from "../../Routes";

export type TalentDisplayProps = {
    talentId: string
    talentName: string
    status?: string
}

const TalentDisplay = ({
    talentId,
    talentName,
    status
}: TalentDisplayProps) => {
    const appPaths = useAppPaths();
    return (
        <>
            <Box key={talentId} sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <AccountCircleIcon fontSize="large" sx={{ marginRight: 1, color: status ? "gray" : "auto" }} />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Link to={appPaths.talents.edit(talentId)}>
                            <Typography component="span" sx={{ fontStyle: status ? "italic" : "auto"}} >
                                {talentName}
                            </Typography>
                            {status && <Typography component="span" className="italic !ml-2">({status})</Typography>}
                        </Link>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default TalentDisplay;