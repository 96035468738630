import { useState } from "react";
import { formatFileSize } from "../../utils/formatFileSizeInKb";

export type UseCsvFileUploadInputStateParams = {
    maxFileSizeKb?: number
}

const useCsvFileUploadInputState = ({
    maxFileSizeKb,
}: UseCsvFileUploadInputStateParams) => {
    const [fileSizeBytes, setFileSizeBytes] = useState<number | undefined>();
    const [fileSizeDescription, setFileSizeDescription] = useState("");
    const [fileName, setFileName] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);
    const [isDirty, setIsDirty] = useState(false);
    const [file, setFile] = useState<File | undefined>();

    const loadFile = async (file: File) => {
        setIsDirty(true);
        setError(undefined);
        setFileSizeBytes(file.size);
        setFile(file);

        try {
            const fileNameParts = file.name.split(".");
            
            if (fileNameParts.length === 1) {
                setError("Please upload a .csv file.");
                return;
            }

            const fileExtension = fileNameParts[fileNameParts.length - 1].toLocaleLowerCase();

            if (file.type != "text/csv") {
                setError(`The file type '${fileExtension}' is not supported. Please upload a .csv file.`);
                return;
            }

        }
        catch (error) {
            setError("Invalid file type, please upload a .csv file.");
            return;
        }

        if (maxFileSizeKb && file.size / 1024 > maxFileSizeKb) {
            setError(`The maximum allowed file size is ${maxFileSizeKb}KB and your file is ${formatFileSize(file.size)}.`);
            return;
        }
                
        setFileName(file.name);
        setFileSizeDescription(formatFileSize(file.size));
    };

    const handleReset = () => {
        setIsDirty(false);
        setError(undefined);
        setFile(undefined);
    };

    return {
        loadFile,
        file,
        fileSizeBytes,
        fileSizeDescription,
        fileName,
        error,
        isDirty,
        reset: handleReset,
    };
};

export default useCsvFileUploadInputState;
