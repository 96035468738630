import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useOrganizationConfig } from "../../api/organization";

export type ClientEditFormValues = {
    name: string
    aboutMe: string
    companyCostCode?: string
    locationId?: string
};

export const useEditClientForm = (initialValues?: ClientEditFormValues) => {
    const schema = clientValidationSchema();
    
    return useForm<ClientEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            ...initialValues,
            name: initialValues?.name ?? "",
            aboutMe: initialValues?.aboutMe ?? "",
            companyCostCode: initialValues?.companyCostCode ?? "",
            locationId: initialValues?.locationId ?? ""
        },
    });
};

const clientValidationSchema = () => {
    const { giggedClientTerminology } = useOrganizationConfig();
    return yup
        .object({
            name: yup.string().required(`${giggedClientTerminology} name is required.`),
            aboutMe: yup.string(),
            companyCostCode: yup.string().max(8, "Cost code cannot be longer than 25 characters."),
        })
        .required();
};