import { useEffect, useState } from "react";
import { Box, Divider, ListItemText, MenuItem, MenuList, useMediaQuery, useTheme } from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterList";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDebounce } from "use-debounce";

import SearchBox from "../SearchBox";
import TalentsTable from "./TalentsTable";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTalents } from "../../api/talents";
import DeletableChips from "../DeletableChips";
import { useAppPaths } from "../../Routes";
import { IconButtonMenu, MenuCheckboxGroup } from "../Menu";

const TalentsTableWithSearch = () => {
    const appPaths = useAppPaths();
    const theme = useTheme();
    const navigate = useNavigate();
    const talents = useTalents();
    const isLargeScreen = useIsLargeScreen();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);
    const { skillIds } = useParams<{ skillIds: string }>();
    const { badgeIds } = useParams<{ badgeIds: string }>();
    const [searchParams] = useSearchParams();
    const filteredSkillName = searchParams.get("skillName") || "";
    const filteredBadgeName = searchParams.get("badgeName") || "";
    const skillIdArray = skillIds?.split(",");
    const badgeIdArray = badgeIds?.split(",");
    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");
    const [includeCompleteProfiles, setIncludeCompleteProfiles] = useState(false);
    const [includeIncompleteProfiles, setIncludeIncompleteProfiles] = useState(false);

    const isProfileCompleteFilter = includeCompleteProfiles === includeIncompleteProfiles ? undefined : includeCompleteProfiles;

    const chips = [
        {
            label: "Yes",
            onDelete: () => setIncludeCompleteProfiles(false),
            visible: includeCompleteProfiles
        },
        {
            label: "No",
            onDelete: () => setIncludeIncompleteProfiles(false),
            visible: includeIncompleteProfiles
        },
    ];

    if (filteredSkillName || filteredBadgeName) {
        chips.push(
            {
                label: `Filtered by: ${filteredSkillName}`,
                onDelete: () => navigate(appPaths.skills.index),
                visible: skillIds ? true : false
            },
            {
                label: `Filtered by: ${filteredBadgeName}`,
                onDelete: () => navigate(appPaths.badges.index),
                visible: badgeIds ? true : false
            },
        );
    }

    const filterChips = (
        <DeletableChips
            chips={chips}
            sx={{
                color: `${theme.palette.tertiary.main} !important`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
                maxWidth: "18rem",
            }}
            deleteIcon={<CloseRoundedIcon />}
        />
    );

    useEffect(() => {
        if (!skillIdArray) return;

        talents.setSkillIds(skillIdArray);
    }, [talents.setSkillIds, skillIds]);

    useEffect(() => {
        if (!badgeIdArray) return;

        talents.setBadgeIds(badgeIdArray);
    }, [talents.setBadgeIds, badgeIds]);

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                justifyContent: "space-between",
                "& > *:not(:first-of-type)": {
                    marginLeft: 1,
                },
            }}>
                <SearchBox
                    placeholder="Search by Name"
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={isLargeScreen ? { minWidth: "28rem", background: "#FFFFFF" } : { flexGrow: 1, background: "#FFFFFF" }}
                />
                {showFilterChipsInline && (
                    <>
                        {filterChips}
                        <Box sx={{ flexGrow: 1 }} />
                    </>
                )}
                <IconButtonMenu
                    id="filter-users"
                    icon={isLargeScreen ? <FilterIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" /> : <MoreVertIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />}

                >
                    <MenuList sx={{ width: "17rem", padding: 0 }}>
                        <MenuCheckboxGroup
                            label="Is profile complete"
                            options={[
                                {
                                    label: "Yes",
                                    value: includeCompleteProfiles,
                                    onChange: setIncludeCompleteProfiles,
                                },
                                {
                                    label: "No",
                                    value: includeIncompleteProfiles,
                                    onChange: setIncludeIncompleteProfiles,
                                },
                            ]}
                        />
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setIncludeCompleteProfiles(false);
                                setIncludeIncompleteProfiles(false);
                            }}
                        >
                            <ListItemText sx={{ textAlign: "right" }}>
                                Clear filters
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </IconButtonMenu>
            </Box>

            {!showFilterChipsInline && (
                <Box sx={{
                    marginTop: "1.5rem"
                }}>
                    {filterChips}
                </Box>
            )}
            <Box sx={{ marginTop: "1.5rem" }}>
                <TalentsTable
                    searchTerm={debounceSearchTerm}
                    skillIds={skillIdArray}
                    badgeIds={badgeIdArray}
                    isProfileCompleteFilter={isProfileCompleteFilter}
                />
            </Box>
        </>
    );
};
export default TalentsTableWithSearch;