import TableChipCell from "../Table/TableChipCell";

type StatusOption = {
    label: string
    value: number
}

export type StatusCellProps = {
    statusId: number
    options: StatusOption[]
}

const StatusCell = ({
    statusId,
    options
}: StatusCellProps) => {
    const option = options.find(_ => _.value === statusId);

    return (
        <TableChipCell labels={[option?.label || ""]} />
    );
};

export default StatusCell;