import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOrganizationConfig } from "../../api/organization";

export type AssignRoleFormValues = {
    message: string
    talentId: string
};

export const assignRoleFormValidationSchemaFields = () => {
    const { talentTerminology } = useOrganizationConfig();

    return yup.object({
        message: yup.string().required("Message is required."),
        talentId: yup.string().required(`Please select ${talentTerminology.toLowerCase()}.`),
    });
};

export const useAssignRoleForm = () => {
    const schema = assignRoleFormValidationSchemaFields();
    return useForm<AssignRoleFormValues>({
        resolver: yupResolver(schema)
    });
};