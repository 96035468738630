import { useNavigate } from "react-router-dom";

import FormContainer from "../components/FormContainer";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useRoles } from "../api/roles";
import { RoleCreateForm, RoleCreateFormValues } from "../components/RoleCreateForm";

const RoleCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createRole, isCreatingRole } = useRoles();

    const onSubmit = async (values: RoleCreateFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createRole({
            ...values,
            skillIds: values.skills.map(_ => _.id)
        });

        if (response.success) {
            successToast("Role successfully created.");
            navigate(appPaths.roles.index);
        }

        return response;
    };

    return (
        <FormContainer>
            <RoleCreateForm onSubmit={onSubmit} isSubmitting={isCreatingRole} />
        </FormContainer>
    );
};

export default RoleCreatePage;
