import { Box, Typography } from "@mui/material";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import RoleCategoriesTable from "../components/RoleCategoriesTable";

const RoleCategoriesListPage = () => {
    const isLargeScreen = useIsLargeScreen();

    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 )}}>
                <Typography component="h1" variant="h4" gutterBottom>Role Categories</Typography>
                <RoleCategoriesTable />
            </Box>
        </>
    );
};

export default RoleCategoriesListPage;