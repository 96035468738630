import { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useDebounce } from "use-debounce";

import SearchBox from "../SearchBox";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import RoleAssignmentsTable from "./RoleAssignmentsTable";
import { useOrganizationConfig } from "../../api/organization";

const RoleAssignmentsTableWithSearch = () => {
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);
    const { talentTerminology, giggedClientTerminology} = useOrganizationConfig();

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                justifyContent: "space-between",
                "& > *:not(:first-of-type)": {
                    marginLeft: 1,
                },
            }}>
                <SearchBox
                    placeholder={isLargeScreen ? `Search by Role, ${talentTerminology} or ${giggedClientTerminology} name` : "Search Assignments"}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={isLargeScreen ? { minWidth: "28rem", background: "#FFFFFF" } : { flexGrow: 1, background: "#FFFFFF" }}
                />
            </Box>
            <Box sx={{ marginTop: "1.5rem" }}>
                <RoleAssignmentsTable
                    searchTerm={debounceSearchTerm}
                />
            </Box>
        </>
    );
};
export default RoleAssignmentsTableWithSearch;