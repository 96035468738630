import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type UserEditFormValues = {
    firstName: string,
    lastName: string,
    isAdmin: number
};

export const useEditUserForm = (initialValues?: Partial<UserEditFormValues>) => {
    return useForm<UserEditFormValues>({
        resolver: yupResolver(userValidationSchema),
        mode: "onSubmit",
        defaultValues: {
            ...initialValues,
            isAdmin: initialValues?.isAdmin ? 1 : 0,
        },
    });
};

const userValidationSchema = yup
    .object({
        firstName: yup.string().required("First name is required."),
        lastName: yup.string().required("Last name is required."),
    })
    .required();

