import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { UserGetMeDto } from "./models/userMe";

export const useUserGetMe = () => {
    const url = "api/me";

    const apiCall = useGiggedApiSWR<UserGetMeDto>(url);

    return {
        talentId: apiCall.data?.talentId,
        clientId: apiCall.data?.giggedClientId,
    };
};