import { useEffect } from "react";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Link } from "react-router-dom";

import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { useAppPaths } from "../../Routes";
import formatDateOnlyString from "../../utils/formatDateString";
import { useRoleCategories } from "../../api/roleCategories";

export type RoleCategoriesTableProps = {
    searchTerm?: string
}

const RoleCategoriesTable = ({ searchTerm }: RoleCategoriesTableProps) => {
    const roleCategories = useRoleCategories();
    const appPaths = useAppPaths();

    const setSearchTerm = roleCategories.setSearchTerm;

    const handleOrderByChange = (property: string) => {
        const isAsc = roleCategories.orderBy === property && roleCategories.sortOrder === "asc";
        roleCategories.setSortOrder(isAsc ? "desc" : "asc");
        roleCategories.setOrderBy(property);
    };

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    if (roleCategories.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "Name",
                        label: "Role category name",
                        isSortable: true,
                    },
                    {
                        key: "RolesCount",
                        label: "Role count",
                        isSortable: true,
                    },
                    {
                        label: "Date created",
                        isSortable: false,
                    },
                    {
                        label: "",
                        isSortable: false
                    }
                ]}
                data={roleCategories.roleCategories}
                renderRow={roleCategory => (
                    <>

                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{roleCategory.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{roleCategory.name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{roleCategory.rolesCount}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(roleCategory.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.roles.categories.edit(roleCategory.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={roleCategories.setPageIndex}
                setPageSize={roleCategories.setPageSize}
                pageSize={roleCategories.pageSize}
                pageIndex={roleCategories.pageIndex}
                totalCount={roleCategories.totalCount}
                orderBy={roleCategories.orderBy}
                sortOrder={roleCategories.sortOrder}
                isLoading={roleCategories.isLoading}
            />
        </TableCard>
    );
};

export default RoleCategoriesTable;
