import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type UserCreateFormValues = {
    firstName: string,
    lastName: string,
    email: string,
    isAdmin: number;
};

export const useUserForm = (initialValues?: Partial<UserCreateFormValues>) => {
    return useForm<UserCreateFormValues>({
        resolver: yupResolver(userValidationSchema),
        mode: "onSubmit",
        defaultValues: {
            ...initialValues,
            isAdmin: initialValues ? initialValues.isAdmin : 0,
        },
    });
};

const userValidationSchema = yup
    .object({
        firstName: yup.string().required("First name is required."),
        lastName: yup.string().required("Last name is required."),
        email: yup.string()
            .email("Must be a valid email e.g. john.smith@yahoo.com")
            .required("Email is required."),
    })
    .required();

