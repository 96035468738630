import FormContainer from "../components/FormContainer";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { capitalizeStrings } from "../utils/capitalizeString";
import { useSkills } from "../api/skills";
import { SkillCreateFormValues, useSkillsForm } from "../components/SkillCreateForm/SkillCreateFormValues";
import SkillCreateForm from "../components/SkillCreateForm/SkillCreateForm";

const SkillCreatePage = () => {
    const { createSkill, isCreatingSkill, addBulkSkillsToOrganization, isAddingBulkSkillsToOrganisation } = useSkills();
    const methods = useSkillsForm();

    const onSubmit = async (values: SkillCreateFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createSkill({
            ...values,
            skillName: capitalizeStrings(values.skillName),
            skillCategoryId: values.skillCategoryId
        });

        if (response.success) {
            methods.reset();
            successToast("Skill successfully added.");
        }

        return response;
    };

    const handleBulkUploadSubmit = async (file: File): Promise<GigApiFetcherResponse<string>> => {
        const formData = new FormData();
        formData.append("File", file);    

        const response = await addBulkSkillsToOrganization(formData);

        if (response.success) {
            successToast("Bulk skills upload completed.");
        }

        return response;
    };

    return (
        <FormContainer>
            <SkillCreateForm onSubmit={onSubmit} isSubmitting={isCreatingSkill || isAddingBulkSkillsToOrganisation} handleSubmitBulkSkills={handleBulkUploadSubmit} methods={methods} />
        </FormContainer>
    );
};

export default SkillCreatePage;
