import { ApiGigSummary } from "../api/models/api/gig";
import { expectedDurationOptions, GigSummary, initialStageOptions, UpdateGigDto } from "../api/models/gig";

export const mapFromApiGigSummary = (apiDto: ApiGigSummary): GigSummary => ({
    ...apiDto,
    startDate: new Date(apiDto.startDate),
    deadlineDate: apiDto.deadlineDate ? new Date(apiDto.deadlineDate) : null,
    expectedDurationLabel: expectedDurationOptions.find(_ => _.value === apiDto.expectedDurationId)?.label || "",
    initialStageLabel: initialStageOptions.find(_ => _.value === apiDto.initialStageId)?.label || "",
});

export const mapToApiGig = <TApi extends UpdateGigDto>(apiDto?: TApi):
    TApi extends undefined ? undefined : Omit<TApi, "startDate" | "deadlineDate"> & { startDate: string, deadlineDate?: string } => {
    if (apiDto === undefined) {
        return undefined as ReturnType<typeof mapToApiGig>;
    }

    return {
        ...apiDto,
        startDate: apiDto.startDate.toISOString(),
        deadlineDate: apiDto.deadlineDate ? apiDto.deadlineDate.toISOString() : undefined,
    } as ReturnType<typeof mapToApiGig>;
};