import Tooltip from "@mui/material/Tooltip";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutline";
import { IconButton } from "@mui/material";

export type HelpIconProps = {
    title: string
}

const HelpIcon = ({
    title
}: HelpIconProps) => {
    return (
        <Tooltip title={title} placement="top" style={{ backgroundColor: "transparent" }}>
            <IconButton>
                <HelpOutlineOutlinedIcon sx={{ color: "black", paddingBottom: 0 }} fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

export default HelpIcon;