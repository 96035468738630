import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { CreateBadgeDto, BadgeSummaryDto } from "./models/badge";

export const useCreateBadge = () => useAuthenticatedGigApiFetcher<CreateBadgeDto, string, CreateBadgeDto>("POST",
    (dto) => ({
        path: "api/badges",
        body: dto
    }));

export const useBadges = (initialPageSize = 10) => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("Name");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const url = "api/badges?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&`;

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<BadgeSummaryDto>>(url);

    const [createBadge, isCreatingBadge] = useCreateBadge();

    return {
        ...apiCall,
        ...apiCall.data,
        data: apiCall.data?.items || [],
        orderBy,
        sortOrder,
        setSearchTerm,
        setOrderBy,
        setSortOrder,
        setPageIndex,
        setPageSize,
        createBadge: async (dto: CreateBadgeDto) => {
            const response = await createBadge(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isCreatingBadge
    };
};