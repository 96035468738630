import { ApiProposalMilestoneSummaryDto } from "./api/proposalMilestone";

export type ProposalSummaryDto = {
    id: string
    estimatedDurationDays: number
    introduction: string
    relevantExperience: string
    portfolioUrl?: string
    proposalStatusId: number
    gig: ApiProposalGigSummary
    gigId: string
    gigTitle: string
    talentId: string
    talentFirstName: string
    talentLastName: string
    createdAt: string
    lastUpdatedAt?: string
    milestones: ApiProposalMilestoneSummaryDto[]
}

export type ProposalDetails = {
    id: string
    estimatedDurationDays: number
    introduction: string
    relevantExperience: string
    portfolioUrl?: string
    gig: ApiProposalGigSummary
    talentId: string
    talentFirstName: string
    talentLastName: string
    createdAt: string
    lastUpdatedAt?: string
    proposalStatusId: number
    milestones: ApiProposalMilestoneSummaryDto[]
}

export type ApiProposalGigSummary = {
    id: string,
    title: string,
    expectedDurationId: number
    startDate: string
    deadlineDate?: string
    description: string
    initialStageId: number
    gigStatusId: number
    giggedClientId: string
    giggedClientName: string
}

export const proposalStatuses = [
    "Pending Review", 
    "Review Rejected", 
    "Submitted", 
    "Hired", 
    "Unsuccessful",
    "Cancelled",
    "Completed"
];