import { useEffect } from "react";
import { IconButton, TableCell } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

import { UseProposalsReturn } from "../../api/proposals";
import { useAppPaths } from "../../Routes";
import formatDateOnlyString from "../../utils/formatDateString";
import Loader from "../Loader";
import Table from "../Table";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import formatDateTimeString from "../../utils/formatDateTimeString";
import { proposalStatuses } from "../../api/models/proposal";
import FilteredLinkCell from "../FilteredLinkCell";
import TableChipCell from "../Table/TableChipCell";

export type ProposalsTableProps = {
    searchTerm?: string,
    proposals: UseProposalsReturn
    gigId?: string
};

const ProposalsTable = ({
    searchTerm,
    proposals,
    gigId
}: ProposalsTableProps) => {
    const appPaths = useAppPaths();
    const { gigTerminology, talentTerminology } = useOrganizationConfig();

    useEffect(() => {
        proposals.setGigId(gigId);
    }, [proposals.setGigId, gigId]);

    useEffect(() => {
        proposals.setSearchTerm(searchTerm);
    }, [proposals.setSearchTerm, searchTerm]);

    const handleOrderByChange = (property: string) => {
        const isAsc = proposals.orderBy === property && proposals.sortOrder === "asc";
        proposals.setSortOrder(isAsc ? "desc" : "asc");
        proposals.setOrderBy(property);
    };

    const headers = [
        {
            label: "ID",
            isSortable: false,
        },
        {
            key: "GigTitle",
            label: gigTerminology,
            isSortable: true
        },
        {
            key: "TalentName",
            label: `${talentTerminology} name`,
            isSortable: true,
        },
        {
            key: "CreatedAt",
            label: "Date created",
            isSortable: true,
        },
        {
            key: "LastUpdatedAt",
            label: "Last updated",
            isSortable: true,
        },
    ];

    headers.push({
        label: "Milestones",
        isSortable: false,
    });

    headers.push(
        {
            key: "ProposalStatusId",
            label: "Status",
            isSortable: true,
        },
        {
            label: "",
            isSortable: false,
        }
    );

    if (proposals.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={headers}
                data={proposals.proposals}
                renderRow={proposal => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{proposal.id}</TableCell>
                        <TableCell sx={{ maxWidth: "250px" }}>{proposal.gigTitle}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{proposal.talentFirstName} {proposal.talentLastName}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(proposal.createdAt)}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{proposal.lastUpdatedAt ? formatDateTimeString(proposal.lastUpdatedAt) : "-"}</TableCell>
                        <FilteredLinkCell
                            count={proposal.milestones ? proposal.milestones.length : 0}
                            href={appPaths.proposals.milestones(proposal.id)}
                        />
                        <TableChipCell labels={[proposalStatuses[proposal.proposalStatusId]]} />
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.proposals.view(proposal.id)}>
                                <IconButton aria-label="view" >
                                    <VisibilityIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={proposals.setPageIndex}
                setPageSize={proposals.setPageSize}
                orderBy={proposals.orderBy}
                sortOrder={proposals.sortOrder}
                pageSize={proposals.pageSize}
                pageIndex={proposals.pageIndex}
                totalCount={proposals.totalCount}
                isLoading={proposals.isLoading}
            />
        </TableCard>
    );
};

export default ProposalsTable;