import { TableCell } from "@mui/material";
import { Link } from "react-router-dom";

export type FilterLinkCellProps = {
    count: number
    href: string
}

const FilterLinkCell = ({
    count,
    href
}: FilterLinkCellProps) => {
    return (
        <TableCell>
            {count !== 0 ? (
                <Link to={href} className="text-link underline">
                    {count}
                </Link>
            ) : count }
        </TableCell>
    );
};

export default FilterLinkCell;