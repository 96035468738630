import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import defaultIfUndefined from "../../utils/defaultIfUndefined";
import { useOrganizationConfig } from "../../api/organization";

export type GigEditFormValues = {
    title: string
    expectedDurationId: number
    initialStageId: number
    description: string
    startDate: Date | null
    deadlineDate: Date | null
    skills: { id: string, name: string }[],
};

export const useEditGigForm = (initialValues?: GigEditFormValues) => {
    const schema = gigEditValidationSchema();
    
    return useForm<GigEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onTouched",
        defaultValues: {
            title: initialValues?.title || "",
            expectedDurationId: defaultIfUndefined(initialValues?.expectedDurationId, -1),
            startDate: initialValues?.startDate || null,
            deadlineDate: initialValues?.deadlineDate || null,
            description: initialValues?.description || "",
            initialStageId: defaultIfUndefined(initialValues?.initialStageId, -1),
            skills: initialValues?.skills || [],
        },
    });
};

const gigEditValidationSchema = () => {
    const { gigTerminology } = useOrganizationConfig();

    return yup
        .object({
            title: yup.string().required(`${gigTerminology} title is required.`),
            description: yup.string().required(`${gigTerminology} description is required.`),
            startDate: yup.date()
                .required("Start date is required.")
                .min(new Date(), "Start date must be later than today.")
                .nullable()
                .typeError("Start date must be a valid date."),
            deadlineDate: yup.date().test("deadline-greater-than-start", (value, context) => {
                if (!value) return true;

                if (value <= context.parent.startDate) {
                    return context.createError({ message: "Deadline must be after start." });
                }

                return true;
            }).nullable().optional().typeError("Deadline must be a valid date."),
            skills: yup
                .array()
                .length(3, "You must select 3 skills")
                .of(
                    yup.object().shape({
                        id: yup.string(),
                        name: yup.string(),
                    }),
                ),
        })
        .required();
};