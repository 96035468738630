import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useAppPaths, useRoleId } from "../Routes";
import { useRole } from "../api/role";
import { RoleEditForm, RoleEditFormValues } from "../components/RoleEditForm";
import RoleViewPage from "../components/RoleView/RoleViewPage";
import { AssignRoleDto } from "../api/models/role";

const RolePage = () => {
    const roleId = useRoleId();
    const { role, updateRole, assignRole, isUpdatingRole, isAssigningRole, isLoading } = useRole(roleId as string);
    const [showEditRole, setShowEditRole] = useState<boolean>(false);
    const appPaths = useAppPaths();
    const navigate = useNavigate();

    useEffect(() => {
        if (!showEditRole) navigate(appPaths.roles.view(roleId));
    }, []);

    const onSubmit = async (values: RoleEditFormValues, openAfterSaving: boolean): Promise<GigApiFetcherResponse<void>> => {

        const response = await updateRole({
            ...values,
            title: values.title,
            description: values.description,
            roleCategoryId: values.roleCategoryId,
            giggedClientId: values.giggedClientId,
            skillIds: values.skills.map(_ => _.id),
            roleStatusId: values.roleStatusId,
            openAfterSaving: openAfterSaving
        });

        if (response.success) {
            openAfterSaving ? successToast("Role updated successfully.") : successToast("Draft updated successfully.");
            navigate(appPaths.roles.view(roleId));
            setShowEditRole(false);
        }

        return response;
    };

    const handleAssign = async (values: AssignRoleDto) => {
        if (!role) return;

        const response = await assignRole(values);

        if (response.success) {
            successToast("Role assignment submitted.");
            window.location.reload();
        }

        return response;
    };

    const handleCloseButton = () => {
        navigate(appPaths.roles.index);
    };

    const handleEditRole = () => {
        navigate(appPaths.roles.edit(roleId));
        setShowEditRole(true);
    };

    const handleCloseEditRolePage = () => {
        setShowEditRole(false);
    };

    if (!role) return <Loader />;

    return (
        <FormContainer>
            {showEditRole ? (
                <RoleEditForm
                    role={role}
                    onSubmit={onSubmit}
                    isSubmitting={isUpdatingRole || isLoading}
                    handleCloseEditRolePage={handleCloseEditRolePage}
                />
            ) : (
                <RoleViewPage
                    role={role}
                    handleCloseButton={handleCloseButton}
                    handleEditRole={handleEditRole}
                    onAssign={handleAssign}
                    loading={isUpdatingRole || isLoading || isAssigningRole}
                />
            )}
        </FormContainer>
    );
};

export default RolePage;
