import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { gigStatuses } from "../../api/models/gig";
import FormCard from "../FormCard";
import Loader from "../Loader";
import { useAppPaths } from "../../Routes";
import CreatedByDisplay from "../CreatedByDisplay";
import { ProposalDetails, proposalStatuses } from "../../api/models/proposal";
import { LoadingButton } from "@mui/lab";
import ApiError from "../../api/common/apiError";
import { useState } from "react";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import FormErrors from "../FormErrors";
import { useOrganizationConfig } from "../../api/organization";

export type GigStatus = keyof typeof gigStatuses;

export type ProposalViewPageProps = {
    proposal: ProposalDetails
    onApprove: () => Promise<GigApiFetcherResponse<void>>
    onReject: () => Promise<GigApiFetcherResponse<void>>
    isApproving: boolean
    isRejecting: boolean
}

const ProposalViewPage = ({
    proposal,
    onApprove,
    onReject,
    isApproving,
    isRejecting
}: ProposalViewPageProps) => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const { gigTerminology, talentTerminology } = useOrganizationConfig();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();

    const handleCloseButton = () => {
        navigate(-1);
    };

    const handleApprove = async () => {
        setSubmissionError(undefined);

        const response = await onApprove();

        if (!response.success) setSubmissionError(response.error);
    };

    const handleReject = async () => {
        setSubmissionError(undefined);

        const response = await onReject();

        if (!response.success) setSubmissionError(response.error);
    };

    if (!proposal) return <Loader />;

    const proposingTalentName = `${proposal.talentFirstName} ${proposal.talentLastName}`;
    const proposalStatus = proposalStatuses[proposal.proposalStatusId];

    return (
        <FormCard title={proposal.gig.title} onClose={handleCloseButton}>
            <Box sx={{
                "> *:not(:last-child)": {
                    marginBottom: theme.spacing(3),
                }
            }}>
                <CreatedByDisplay createdAt={proposal.createdAt} updatedAt={proposal.lastUpdatedAt} />
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    ID: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.id}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    {talentTerminology}: <Typography sx={{ marginLeft: "8px" }} component="span">{proposingTalentName}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    {gigTerminology}: <Link className="ml-2 font-normal underline" to={appPaths.gigs.view(proposal.gig.id)}>{proposal.gig.title}</Link>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Milestones: <Link to={appPaths.proposals.milestones(proposal.id)} className="text-link underline ml-2"> {proposal.milestones ? proposal.milestones.length : 0} </Link>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Status: <Typography sx={{ marginLeft: "8px" }} component="span">{proposalStatus}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Days to complete: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.estimatedDurationDays}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Portfolio URL: <a className="ml-2 cursor-pointer underline font-normal" href={proposal.portfolioUrl} target="blank">{proposal.portfolioUrl}</a>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Intro: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.introduction}</Typography>
                </Typography>
                <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                    Experience: <Typography sx={{ marginLeft: "8px" }} component="span">{proposal.relevantExperience}</Typography>
                </Typography>
                <FormErrors messages={submissionError?.userMessages} />
                {proposal.proposalStatusId === 0 && (
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <LoadingButton
                            disabled={isRejecting || proposal.proposalStatusId !== 0}
                            loading={isRejecting}
                            color="secondary"
                            onClick={handleReject}
                        >
                            Reject
                        </LoadingButton>
                        <LoadingButton
                            disabled={isApproving || proposal.proposalStatusId !== 0}
                            loading={isApproving}
                            color="secondary"
                            onClick={handleApprove}
                        >
                            Approve
                        </LoadingButton>
                    </Box>
                )}
            </Box>
        </FormCard>
    );
};

export default ProposalViewPage;