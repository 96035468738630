export type ApplicationSummaryDto = {
    id: string
    roleTitle: string
    giggedClientName: string
    talentFirstName: string
    talentLastName: string
    applicationStatusId: number
    createdAt: string
}

export type ApplicationDetailsDto = {
    id: string
    coverLetter: string
    portfolioUrl: string
    role: ApplicationRole
    talentId: string
    talentFirstName: string
    talentLastName: string
    applicationStatusId: number
    createdAt: string
    lastUpdatedAt: string
}

export type ApplicationRole = {
    id: string
    title: string
    description: string
    giggedClientId: string
    giggedClientName: string
    roleStatusId: number
}

export const applicationStatuses = {
    draft: 0,
    submitted: 1,
    hired: 2,
    unsuccessful: 3,
    cancelled: 4,
    completed: 5,
    pending: 6
};